<template>
  <div class="up_box" :class="!system.isPhone ? 'pc' : 'wap'">
    <el-form-item :prop="prop" :label="label" :rules="rules">
      <el-upload
        class="upload-demo"
        :headers="headers()"
        :on-change="handleChange"
        :action="
          `/api/front/daemon/files/upload?kind=attachment&sourceId=${new Date().getTime()}`
        "
        :file-list="dataListList || []"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          <div v-for="(item, index) in tips" :key="index" class="tip_pad">
            {{ item }}
          </div>
        </div>
      </el-upload>
    </el-form-item>
  </div>
</template>
<script>
export default {
  props: ["dataList", "label", "prop", "tips", "rules"],
  computed: {
    dataListList: {
      get: function() {
        return this.dataList;
      },
      set: function(value) {
        this.$emit("update:dataList", value);
      }
    }
  },
  mounted() {
    console.log(this.value, "value");
  },
  methods: {
    headers() {
      let headers = {
        Accept: "application/json",
        "App-Id": "16adaee5-c23f-44f6-b6b3-c67e9aa7e45b"
      };
      const token = localStorage.getItem("AZG_TOKEN");
      if (token) {
        headers = {
          ...headers,
          "Authorization-admin": token
        };
      }
      return headers;
    },
    handleChange(file, fileList) {
      this.$emit("update:dataList", fileList);
    }
  }
};
</script>
<style lang="less" scoped>
.tip_pad {
  line-height: 20px;
}
</style>
