<template>
  <div class="main_containter" :class="system.isPhone ? 'wap' : 'pc'">
    <!-- 上传单位证件图片 -->
    <div style="padding-left: 26px;">
      <el-row :gutter="gutter">
        <el-col v-bind="row2">
          <uplode-input3
            :asyncUpdate="true"
            tip="法人身份证人像面"
            prop="idcardFrontUrl"
            :rules="rules.idcardFrontUrl"
            :label="$t(`popFillInInfomation.legalPersonInfo.idcardFrontUrl`)"
            keyName="idcardFrontUrl"
            :value="form.idcardFrontUrl"
            @uplodeChange="getIdcardUrlInfo"
          ></uplode-input3>
        </el-col>
        <el-col v-bind="row2">
          <uplode-input3
            :asyncUpdate="true"
            tip="法人身份证国徽面"
            prop="idcardBackUrl"
            :rules="rules.idcardBackUrl"
            :label="$t(`popFillInInfomation.legalPersonInfo.idcardBackUrl`)"
            keyName="idcardBackUrl"
            :value="form.idcardBackUrl"
            @uplodeChange="getIdcardUrlInfo"
          ></uplode-input3>
        </el-col>
      </el-row>
    </div>
    <div class="content_box">
      <el-row :gutter="gutter">
        <!--法人姓名  -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="idcardName"
            :rules="rules.idcardName"
            v-model="form.idcardName"
            :label="$t(`popFillInInfomation.legalPersonInfo.idcardName`)"
          ></text-input>
        </el-col>

        <!--法人身份证号：  -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="idcardNumber"
            :rules="rules.idcardNumber"
            v-model="form.idcardNumber"
            :label="$t(`popFillInInfomation.legalPersonInfo.idcardNumber`)"
          ></text-input>
        </el-col>

        <!--身份证有效期起：  -->
        <el-col v-bind="row2">
          <el-form-item
            prop="idcardStartDate"
            :label="$t(`popFillInInfomation.legalPersonInfo.idcardStartDate`)"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.idcardStartDate"
              type="date"
              v-bind="otherBind"
              placeholder="请选择"
              :editable="!system.isPhone"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <!--身份证有效期止：  -->
        <el-col v-bind="row2">
          <el-form-item
            ref="idcardEndDate"
            :prop="
              form.idcardLongPeriod == 1 ? 'idcardLongPeriod' : 'idcardEndDate'
            "
            :label="$t(`popFillInInfomation.legalPersonInfo.idcardEndDate`)"
          >
            <div class="yihang">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.idcardEndDate"
                type="date"
                v-bind="otherBind"
                placeholder="请选择"
                :editable="!system.isPhone"
                :disabled="form.idcardLongPeriod == 1"
              >
              </el-date-picker>
              <el-checkbox
                @change="changqiEvent"
                :true-label="1"
                :false-label="0"
                v-model="form.idcardLongPeriod"
                t="长期"
              >
                {{ $t(`popFillInInfomation.longTime`) }}
              </el-checkbox>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
//公共组件导入处;
import uplodeInput3 from "@/views/formComponents/uplodeInput3";
import textInput from "@/views/formComponents/textInput";
//子组件导入处;

//方法导入处;

export default {
  components: { uplodeInput3, textInput },
  // model: {
  //   prop: "formData",
  //   event: "update"
  // },
  data() {
    return {
      otherBind: {
        size: "medium"
      }
    };
  },
  props: {
    row2: [Object],
    gutter: [Number],
    formData: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    form: {
      get: function() {
        return this.formData;
      },
      set: function(value) {
        this.$emit("update:formData", value);
      }
    }
  },
  methods: {
    uplodeChange(value) {
      console.log("更新数据:", value);
      this.form = { ...this.form, ...value };
      this.$parent.$parent.$parent.$refs["applyAgentForm"].validateField(
        Object.keys(value)[0]
      );
    },
    // uplodeChangeBack(value) {
    //   console.log("处理国徽面:", value);
    //   this.form = { ...this.form, ...value };
    //   this.$parent.$parent.$parent.$refs["applyAgentForm"].validateField(
    //     Object.keys(value)[0]
    //   );
    //   this.getIdcardBackUrlInfo(value.idcardBackUrl);
    // },

    handleChange(value) {
      console.log(this.$refs.asdfasdf.getCheckedNodes());
      console.log(value, arguments);
    },
    changqiEvent() {
      this.$refs["idcardEndDate"].resetField();
      // this.$parent.$parent.$parent.$refs["idcardEndDate"].resetFields();
    },
    getIdcardUrlInfo(value) {
      this.$message({
        message: "请稍等，识别中...",
        type: "none",
        // duration: 0,
        customClass: "zidingyiel_message"
      });
      this.$request("/api/front/daemon/ocr/ocrIdCardFront", {
        data: {
          ocrImage: Object.values(value)[0]
        },
        method: "POST"
      })
        .then(res => {
          if (res.code == 1 && res?.data?.msg) {
            let msg = res.data.msg;

            if (res.data.image_status == "reversed_side") {
              this.form.idcardStartDate =
                msg.begindate.substr(0, 4) +
                "-" +
                msg.begindate.substr(4, 2) +
                "-" +
                msg.begindate.substr(6);
              if (msg.enddate == "长期") {
                this.form.idcardLongPeriod = 1;
              } else {
                this.form.idcardEndDate =
                  msg.enddate.substr(0, 4) +
                  "-" +
                  msg.enddate.substr(4, 2) +
                  "-" +
                  msg.enddate.substr(6);
              }
              this.uplodeChange({
                idcardBackUrl: Object.values(value)[0]
              });
            }
            if (res.data.image_status == "normal") {
              this.form.idcardName = msg.name;
              this.form.idcardNumber = msg.idcardno;
              this.uplodeChange({
                idcardFrontUrl: Object.values(value)[0]
              });
            }

            console.log(msg.name, msg.idcardno, 555555555);

            // if (msg.name) {
            //   this.form.idcardName = msg.name;
            //   this.form.idcardNumber = msg.idcardno;
            //   //人像面照片
            //   if (
            //     !Object.prototype.hasOwnProperty.call(value, "idcardFrontUrl")
            //   ) {
            //     this.uplodeChange({
            //       idcardFrontUrl: Object.values(value)[0]
            //     });
            //   } else {
            //     this.uplodeChange(value);
            //   }
            // } else {
            //   if (msg.begindate) {
            //     msg.begindate = msg.begindate.match(/(\d{4})(\d{2})(\d{2})/);
            //     msg.begindate.shift();
            //     this.form.idcardStartDate = msg.begindate.join("-");
            //   }
            //   if (msg.enddate) {
            //     msg.enddate = msg.enddate.match(/(\d{4})(\d{2})(\d{2})/);
            //     msg.enddate.shift();
            //     this.form.idcardEndDate = msg.enddate.join("-");
            //   }
            //   // 国徽面
            //   if (
            //     !Object.prototype.hasOwnProperty.call(value, "idcardBackUrl")
            //   ) {
            //     this.uplodeChange({
            //       idcardBackUrl: Object.values(value)[0]
            //     });
            //   } else {
            //     this.uplodeChange(value);
            //   }
            // }
          } else {
            console.log("不是身份证照片");
            this.$message({
              message: "识别出非证照图片，请重新上传！",
              type: "error"
            });
            this.uplodeChange(value);
          }
          console.log(res);
        })
        .catch(() => {});
    }
    // getIdcardBackUrlInfo(value) {
    //   this.$request("/api/front/daemon/ocr/ocrIdCardBack", {
    //     data: {
    //       ocrImage: value
    //     },
    //     method: "POST"
    //   })
    //     .then(res => {
    //       if (res.code == 1 && res?.data?.msg) {
    //         let msg = res.data.msg;
    //         this.form.idcardStartDate = msg.begindate;
    //         this.form.idcardEndDate = msg.enddate;
    //       }
    //       console.log(res);
    //     })
    //     .catch(() => {});
    // }
  },

  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.content_box {
  border: 1px solid rgba(18, 18, 18, 0.05);
  border-radius: 2px;
  padding: 24px;
  margin-top: 12px;
}
::v-deep .el-cascader--medium,
.el-date-editor {
  width: 100%;
}
.yihang {
  display: flex;
  align-items: center;
  gap: 14px;
}
.wap {
  .content_box {
    margin-top: 24px;
  }
}
</style>
