<template>
  <div class="main_containter" :class="system.isPhone ? 'wap' : 'pc'">
    <div class="pd_box" style="padding-left: 26px;">
      <el-row :gutter="gutter">
        <!-- 店铺类型 -->
        <el-col v-bind="row2">
          <el-form-item prop="storeType">
            <!-- <template #label> -->
            <el-radio
              @change="shopTypeChange"
              v-for="(item, index) in $t(`popFillInInfomation.popShopInfo`)"
              :key="index"
              v-model="form.storeType"
              :label="item.value"
            >
              {{ item.shopType }}
            </el-radio>
            <!-- </template> -->
            <div class="miaoshu_box">
              <ul>
                <li class="head_box">
                  <img :src="require('@/assets/img/pop/pic-头像@2x.jpg')" />
                  <span class="font600w"> {{ getSubInfo.shopType }}</span>
                </li>
                <li v-for="(item, index) in getSubInfo.sub" :key="index">
                  {{ item }}
                </li>
              </ul>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      otherBind: {
        size: "medium"
      }
    };
  },
  props: {
    row2: [Object],
    gutter: [Number],
    formData: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    form: {
      get: function() {
        return this.formData;
      },
      set: function(value) {
        this.$emit("update:formData", value);
      }
    },
    getSubInfo() {
      let arr = this.$t(`popFillInInfomation.popShopInfo`);
      let info = arr.filter(item => item.value == this.form.storeType);
      this.initStoreName(info[0]);
      return info[0];
    },
    getOtherTipList() {
      return this.$t(`popFillInInfomation.popShopInfoObj.shopLogoTipList`);
    }
  },
  methods: {
    // 根据品牌名称自动生成店铺名
    initStoreName(getSubInfo) {
      console.log(getSubInfo);
      // let value = this.form.brandName;
      // this.form.storeName = value ? `${value}${getSubInfo.shopType}` : null;
    },

    shopTypeChange() {
      let e = this.getSubInfo;
      if ([1, 3].indexOf(e.value) != -1) {
        alert("请注意，该类型店铺允许仅有一个品牌");
      } else if ([4, 5].indexOf(e.value) != -1) {
        alert("请注意，该类型店铺允许有多个品牌");
      }
    },
    uplodeChange(value) {
      console.log("处理店铺照片:", value);
      this.form = { ...this.form, ...value };
      this.$parent.$parent.$parent.$refs["applyAgentForm"].validateField(
        Object.keys(value)[0]
      );
    }
  }
};
</script>
<style scoped lang="less">
.pc {
  ::v-deep .el-form-item__content {
    margin-left: 120px !important;
  }
}

.miaoshu_box {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 2px;
  max-width: 540px;
  padding: 24px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
}
.head_box {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  color: black;
  font-size: 14px;
  img {
    width: 50px;
    height: 50px;
  }
}
.wap {
  .pd_box {
    // padding-left: 12px;
  }
}
</style>
