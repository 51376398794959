var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main_containter",class:_vm.system.isPhone ? 'wap' : 'pc'},[_c('el-row',{attrs:{"gutter":_vm.gutter}},[_c('el-col',_vm._b({},'el-col',_vm.row1,false),[_c('el-form-item',{attrs:{"prop":'popStoreBrandList.' + _vm.itemIndex + '.brandType',"rules":_vm.rules.brandType,"label":_vm.$t("popFillInInfomation.brand.brandType")}},[_c('el-radio-group',{model:{value:(_vm.formItem.brandType),callback:function ($$v) {_vm.$set(_vm.formItem, "brandType", _vm._n($$v))},expression:"formItem.brandType"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v(" "+_vm._s(_vm.$t("popFillInInfomation.brand.brandTypeRadio1"))+" ")]),_c('el-radio',{attrs:{"label":2}},[_vm._v(" "+_vm._s(_vm.$t("popFillInInfomation.brand.brandTypeRadio2"))+" ")])],1)],1)],1),_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('el-form-item',{staticClass:"zai_css",attrs:{"prop":'popStoreBrandList.' + _vm.itemIndex + '.brandName',"rules":_vm.rules.brandName,"label":_vm.$t("popFillInInfomation.brand.brandInfo.brandName")}},[_c('el-autocomplete',{staticStyle:{"width":"100%"},attrs:{"fetch-suggestions":function (name, callback) { return _vm.remoteMethod({ name: name }, callback); },"placeholder":"请输入内容"},on:{"select":function (item) { return _vm.handleBrandChange(_vm.itemIndex, 1, item); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getOptionString(item)))])]}}]),model:{value:(_vm.formItem.brandName),callback:function ($$v) {_vm.$set(_vm.formItem, "brandName", $$v)},expression:"formItem.brandName"}})],1)],1),_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('el-form-item',{staticClass:"zai_css",attrs:{"prop":'popStoreBrandList.' + _vm.itemIndex + '.trademarkSn',"rules":_vm.rules.trademarkSn,"label":_vm.$t("popFillInInfomation.brand.brandInfo.trademarkSn")}},[_c('el-autocomplete',{staticStyle:{"width":"100%"},attrs:{"fetch-suggestions":function (trademarkSn, callback) { return _vm.remoteMethod({ trademarkSn: trademarkSn }, callback); },"placeholder":"请输入内容","disabled":_vm.otherEditStatus.trademarkSnEditStatus},on:{"select":function (item) { return _vm.handleBrandChange(_vm.itemIndex, 2, item); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getOptionString(item)))])]}}]),model:{value:(_vm.formItem.trademarkSn),callback:function ($$v) {_vm.$set(_vm.formItem, "trademarkSn", $$v)},expression:"formItem.trademarkSn"}})],1)],1),_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('text-input',{attrs:{"otherBind":_vm.otherBind,"prop":'popStoreBrandList.' + _vm.itemIndex + '.trademarkSnOwner',"rules":_vm.rules.trademarkSnOwner,"label":_vm.$t("popFillInInfomation.brand.brandInfo.trademarkSnOwner"),"disabled":_vm.otherEditStatus.trademarkSnOwner},model:{value:(_vm.formItem.trademarkSnOwner),callback:function ($$v) {_vm.$set(_vm.formItem, "trademarkSnOwner", $$v)},expression:"formItem.trademarkSnOwner"}})],1),_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('el-form-item',{attrs:{"prop":'popStoreBrandList.' + _vm.itemIndex + '.certificationTime',"rules":_vm.rules.certificationTime,"label":_vm.$t("popFillInInfomation.brand.registration.certificationTime"),"disabled":_vm.otherEditStatus.certificationTime}},[_c('el-date-picker',_vm._b({attrs:{"value-format":"yyyy-MM-dd","format":"yyyy-MM-dd","type":"date","placeholder":"请选择","editable":!_vm.system.isPhone,"disabled":_vm.otherEditStatus.certificationTime},model:{value:(_vm.formItem.certificationTime),callback:function ($$v) {_vm.$set(_vm.formItem, "certificationTime", $$v)},expression:"formItem.certificationTime"}},'el-date-picker',_vm.otherBind,false))],1)],1),_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('uplode-input3',{attrs:{"prop":'popStoreBrandList.' + _vm.itemIndex + '.certificationPicUrl',"rules":[
          Object.assign({}, _vm.rules.certificationPicUrl[0],
            {itemIndex: _vm.itemIndex}) ],"label":_vm.$t("popFillInInfomation.brand.certificationPicUrl"),"keyName":"certificationPicUrl","value":_vm.formItem.certificationPicUrl,"disabled":_vm.otherEditStatus.certificationPicUrl},on:{"uplodeChange":_vm.uplodeChange},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( "popFillInInfomation.brand.registration.certificationPicUrlTip" ))+" ")]},proxy:true}])})],1),_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('uplode-input3',{attrs:{"prop":'popStoreBrandList.' + _vm.itemIndex + '.brandLogo',"label":_vm.$t("popFillInInfomation.brand.brandInfo.brandLogo"),"keyName":"brandLogo","rules":[
          Object.assign({}, _vm.rules.brandLogo[0],
            {itemIndex: _vm.itemIndex}) ],"value":_vm.formItem.brandLogo,"limit":2,"disabled":_vm.otherEditStatus.brandLogo},on:{"uplodeChange":_vm.uplodeChange},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('div',[_c('ul',_vm._l((_vm.getOtherTipList),function(item){return _c('li',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]},proxy:true}])})],1),(_vm.formItem.brandType == 2)?_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('text-input',{attrs:{"otherBind":_vm.otherBind,"rules":[
          Object.assign({}, _vm.rules.authorizationLevel[0],
            {itemIndex: _vm.itemIndex}) ],"prop":'popStoreBrandList.' + _vm.itemIndex + '.authorizationLevel',"label":_vm.$t("popFillInInfomation.brand.authorizationLevel")},on:{"onChange":_vm.initStoreName},model:{value:(_vm.formItem.authorizationLevel),callback:function ($$v) {_vm.$set(_vm.formItem, "authorizationLevel", $$v)},expression:"formItem.authorizationLevel"}})],1):_vm._e(),(_vm.formItem.brandType == 2)?_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('div',{staticClass:"width100"},[_vm._v(" 授权等级，指开店主体所持有的品牌商标授权等级，分为一级独占授权、一级授权，二级授权、三级授权和仅采销链路等 ")])]):_vm._e(),(_vm.formItem.brandType == 2)?_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('el-form-item',{attrs:{"prop":("popStoreBrandList." + _vm.itemIndex + (_vm.formItem.authorizationLongPeriod
              ? '.authorizationLongPeriod'
              : '.authorizationPeriod')),"rules":[
          Object.assign({}, (_vm.formItem.authorizationLongPeriod
              ? _vm.rules.authorizationLongPeriod[0]
              : _vm.rules.authorizationPeriod[0]),
            {itemIndex: _vm.itemIndex}) ],"label":_vm.$t("popFillInInfomation.brand.authorizationPeriod")}},[_c('div',{staticClass:"yihang"},[_c('el-date-picker',_vm._b({attrs:{"value-format":"yyyy-MM-dd","type":"date","placeholder":"请选择","editable":!_vm.system.isPhone,"disabled":_vm.formItem.authorizationLongPeriod == 1},model:{value:(_vm.formItem.authorizationPeriod),callback:function ($$v) {_vm.$set(_vm.formItem, "authorizationPeriod", $$v)},expression:"formItem.authorizationPeriod"}},'el-date-picker',_vm.otherBind,false)),_c('el-checkbox',{attrs:{"true-label":1,"false-label":0,"t":"长期"},on:{"change":function($event){return _vm.changqiEvent($event, _vm.formItem)}},model:{value:(_vm.formItem.authorizationLongPeriod),callback:function ($$v) {_vm.$set(_vm.formItem, "authorizationLongPeriod", $$v)},expression:"formItem.authorizationLongPeriod"}},[_vm._v(" "+_vm._s(_vm.$t("popFillInInfomation.longTime"))+" ")])],1)])],1):_vm._e(),_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('div',{staticClass:"width100"})]),(_vm.formItem.brandType == 2)?_c('el-col',_vm._b({},'el-col',_vm.row2,false),[_c('upload-file-more',{attrs:{"prop":'popStoreBrandList.' + _vm.itemIndex + '.brandQualificationUrl',"rules":[Object.assign({}, _vm.rules.brandQualificationUrl[0], {itemIndex: _vm.itemIndex})],"tips":_vm.tips,"dataList":_vm.formItem.brandQualificationUrl,"label":_vm.$t("popFillInInfomation.brand.brandQualificationUrl")},on:{"update:dataList":function($event){return _vm.$set(_vm.formItem, "brandQualificationUrl", $event)},"update:data-list":function($event){return _vm.$set(_vm.formItem, "brandQualificationUrl", $event)}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }