<template>
  <div class="get_code_but_box" @click="getCOdeEvent">
    <slot :info="{ canDo: codeButClor, text: getCOdeBut }">
      <div class="geit_code_icon" :class="{ cantrue: codeButClor }">
        {{ getCOdeBut }}
      </div>
    </slot>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;
import { verifyPhone } from "@/utils/pattern.js";
export default {
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          phone: null
        };
      }
    },
    phone: [Number, String],
    // 等待时间间隔，单位，秒
    lodingTime: {
      type: Number,
      default: 60
    },
    // 验证码发送类型
    kind: {
      type: String,
      default: null
    },
    // formData里要监听的字段，一般是电话号码的键名
    watchKey: {
      type: String,
      default: "phone"
    }
  },
  watch: {
    phone: {
      handler: function(value) {
        this.canSendCode = verifyPhone(value);
      },
      immediate: true,
      deep: true
    }
  },
  components: {},
  data() {
    return {
      getCOdeBut: this.$t("getCode"),
      times: this.lodingTime ? this.lodingTime : 60,
      getCodeButStatus: true,
      canSendCode: false
    };
  },
  computed: {
    codeButClor: function() {
      if (this.canSendCode && this.getCodeButStatus) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    change(value) {
      this.$emit("input", value);
    },
    getCOdeEvent() {
      if (this.canSendCode == false || this.getCodeButStatus == false) {
        return;
      }
      this.getCodeButStatus = false;
      this.$emit("sendCodeButEvent", "loding");
      const data = {
        phone: this.phone
      };
      if (this.kind) {
        data.kind = this.kind;
      }
      console.log(data);
      // return;
      const url = "/api/front/user/sendSms";
      this.sending = true;
      this.$request(url, {
        method: "POST",
        data
      })
        .then(res => {
          if (res.code === 1) {
            this.lodingCountdown(1);
            this.$message({
              message: "验证码已发送到您的手机",
              type: "none",
              customClass: "zidingyiel_message"
            });
          } else {
            this.$message({
              message: "获取验证码失败，请稍后再试！",
              type: "error"
            });
            this.getCodeButStatus = true;
            this.$emit("sendCodeButEvent", "false");
          }
        })
        .catch(() => {
          this.$emit("sendCodeButEvent", "false");
        });
    },
    lodingCountdown(t) {
      this.getCOdeBut = `${this.times - t}${this.$t("daojishi")}`;
      if (t >= this.times) {
        this.getCodeButStatus = true;
        this.$emit("sendCodeButEvent", "true");
        this.getCOdeBut = `${this.$t("getCodeOnece")}`;
        return;
      }
      setTimeout(() => {
        this.lodingCountdown(t + 1);
      }, 1000);
    }
  },
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.geit_code_icon {
  cursor: pointer;
}
.cantrue {
  color: #409eff;
}
.get_code_but_box {
  cursor: pointer;
}
</style>
