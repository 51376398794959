<template>
  <div class="">
    <el-form-item :prop="prop" :label="label" :rules="rules">
      <el-input
        :placeholder="p"
        :type="new_inputType"
        :value="value"
        @input="change"
        :maxlength="maxlength"
        :minlength="minlength"
        v-bind="otherBind"
        :disabled="disabled"
      >
        <template slot="append">
          <slot name="append"></slot>
        </template>
        <template slot="prepend">
          <slot name="prepend"></slot>
        </template>
        <!-- <div class="eyes_icon" @click="changeInputTypeEvent" slot="suffix">
          <img
            v-if="new_inputType == 'password'"
            :src="require('@/assets/img/login/nengkan_bu.png')"
          />
          <img v-else :src="require('@/assets/img/login/nengkan.png')" />
        </div> -->
        <img v-if="l_icon" class="prefix_img" slot="prefix" :src="l_icon" />
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  // props: ["value", "prop", "inputType", "p", "label", "l_icon"],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: "",
    },
    prop: {
      default: "",
    },
    inputType: {
      default: "",
    },
    p: {
      default: "",
    },
    label: {
      default: "",
    },
    l_icon: {
      default: "",
    },
    maxlength: {
      default: 50,
    },
    minlength: {
      default: 0,
    },
    // 其它自定义属性绑定
    otherBind: {
      type: Object,
      default: () => {},
    },
    // 校验方法
    rules: {
      type: [Array, Object, Function],
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      valueData: this.value,
      new_inputType: this.inputType,
    };
  },

  computed: {},
  methods: {
    change(value) {
      console.log(value);
      this.$emit("input", value);
      this.$emit("onChange", value);
    },
    changeInputTypeEvent() {
      this.new_inputType == "text"
        ? (this.new_inputType = "password")
        : (this.new_inputType = "text");
    },
  },
  mounted() {},
  beforeDestroy() {},
  activated() {},
};
</script>
<style scoped lang="less">
.prefix_img {
  width: 18px;
  height: auto;
}
.eyes_icon {
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
}
</style>
