<template>
  <div class="main_containter" :class="system.isPhone ? 'wap' : 'pc'">
    <!-- 主体类型 -->
    <div style="padding-left: 26px">
      <el-form-item
        prop="subjectType"
        class="zai_css"
        label-width="150px"
        :label="$t(`popFillInInfomation.corporateInfo.subjectType`)"
      >
        <div>
          <el-radio-group v-model.number="form.subjectType">
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                $t(`popFillInInfomation.corporateInfo.zhongguoCorporateDesc`)
              "
              placement="top-start"
            >
              <el-radio :label="1">
                {{ $t(`popFillInInfomation.corporateInfo.zhongguoCorporate`) }}
              </el-radio>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                $t(`popFillInInfomation.corporateInfo.haiwaiCorporateDesc`)
              "
              placement="top-start"
            >
              <el-radio :label="2">
                {{ $t(`popFillInInfomation.corporateInfo.haiwaiCorporate`) }}
              </el-radio>
            </el-tooltip>
          </el-radio-group>
        </div>
      </el-form-item>
    </div>
    <!-- 国内主体 -->
    <div v-if="form.subjectType == 1">
      <!-- 上传单位证件图片 -->
      <div style="padding-left: 26px">
        <uplode-input3
          :asyncUpdate="true"
          tip="公司的营业执照"
          prop="businessLicenseUrl"
          :rules="rules.businessLicenseUrl"
          :label="$t(`popFillInInfomation.corporateInfo.businessLicenseUrl`)"
          keyName="businessLicenseUrl"
          :value="form.businessLicenseUrl"
          @uplodeChange="getBusinessLicenseUrlInfo"
        ></uplode-input3>
      </div>
      <div class="content_box">
        <el-row :gutter="gutter">
          <!--公司名称  -->
          <el-col v-bind="row2">
            <text-input
              :otherBind="otherBind"
              prop="companyName"
              :rules="rules.companyName"
              v-model="form.companyName"
              :label="$t(`popFillInInfomation.corporateInfo.companyName`)"
            ></text-input>
          </el-col>

          <!--社会统一信用代码  -->
          <el-col v-bind="row2">
            <text-input
              :otherBind="otherBind"
              prop="creditCode"
              :rules="rules.creditCode"
              v-model="form.creditCode"
              :label="$t(`popFillInInfomation.corporateInfo.creditCode`)"
            ></text-input>
          </el-col>

          <!--营业执照注册地址  -->
          <!-- <el-col v-bind="row2">
          <el-form-item
            prop="zhuchedizhi"
            :label="$t(`popFillInInfomation.corporateInfo.zhuchedizhi`)"
          >
            <el-cascader
              ref="zhuchedizhi"
              v-model="form.zhuchedizhi"
              :options="addressList"
              :props="cascaderProps"
              placeholder="请选择省市区"
              @change="handleChange"
              v-bind="otherBind"
              @focus="addressListFocus"
            />
          </el-form-item>
        </el-col> -->

          <!--详细地址  -->
          <el-col v-bind="row2">
            <text-input
              :otherBind="otherBind"
              prop="address"
              :rules="rules.address"
              v-model="form.address"
              :label="$t(`popFillInInfomation.corporateInfo.address`)"
            ></text-input>
          </el-col>

          <!--公司成立时间  -->
          <el-col v-bind="row2">
            <el-form-item
              prop="startDate"
              :label="$t(`popFillInInfomation.corporateInfo.startDate`)"
            >
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.startDate"
                type="date"
                v-bind="otherBind"
                placeholder="请选择"
                :editable="!system.isPhone"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <!--经营期限  -->
          <el-col v-bind="row2">
            <el-form-item
              ref="validPeriod"
              :prop="form.longPeriod ? 'longPeriod' : 'validPeriod'"
              :label="$t(`popFillInInfomation.corporateInfo.validPeriod`)"
            >
              <div class="yihang">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="form.validPeriod"
                  type="date"
                  v-bind="otherBind"
                  placeholder="请选择"
                  :editable="!system.isPhone"
                  :disabled="form.longPeriod == 1"
                >
                </el-date-picker>
                <el-checkbox
                  @change="changqiEvent"
                  :true-label="1"
                  :false-label="0"
                  v-model="form.longPeriod"
                  t="长期"
                >
                  {{ $t(`popFillInInfomation.longTime`) }}
                </el-checkbox>
              </div>
            </el-form-item>
          </el-col>

          <!-- 注册资本 -->
          <!-- <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="registeredCapital"
            v-model="form.registeredCapital"
            :label="$t(`popFillInInfomation.corporateInfo.registeredCapital`)"
          ></text-input>
        </el-col> -->
        </el-row>
      </div>
    </div>
    <!-- 跨境主体  -->
    <div v-if="form.subjectType == 2" style="padding-left: 26px">
      <!-- 注册登记证明 -->
      <!-- <registrationCertificate
        :rules="rules"
        :formData.sync="form"
        :row1="row1"
        :gutter="gutter"
      ></registrationCertificate> -->
      <el-row :gutter="gutter">
        <el-col v-bind="row1">
          <upload-more
            v-model="form.registerCertificationUrl"
            prop="registerCertificationUrl"
            :rules="rules.registerCertificationUrl"
            :label="
              $t(`popFillInInfomation.corporateInfo.registerCertificationUrl`)
            "
          ></upload-more>
          <el-form-item>
            <div class="width100 width_line">
              请上传一张公司的营业执照扫描件并加盖z公章。大小5M以内，支持jpg，png格式，要求内容清晰可见。
            </div>
            <div class="width100 width_line">
              对于新办理的营业执照，因国家市场监督管理总局信息更新有延迟，建议办理成功后至少等待7个工作日后再入驻。
            </div>
          </el-form-item>
        </el-col>
        <!--公司名称  -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="companyName"
            :rules="rules.companyName"
            v-model="form.companyName"
            :label="$t(`popFillInInfomation.corporateInfo.companyName`)"
          ></text-input>
        </el-col>
        <el-col v-bind="row2">
          <div class="width100"></div>
        </el-col>
        <!--登记证编号(Certificate NO.)  -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="creditCode"
            :rules="rules.creditCode2"
            v-model="form.creditCode"
            :label="$t(`popFillInInfomation.corporateInfo.creditCode2`)"
          ></text-input>
        </el-col>
        <el-col v-bind="row2">
          <div class="width100">
            若为中国香港主体，请填写公司注册证书上的编号
          </div>
        </el-col>
        <!--注册登记日期  -->
        <el-col v-bind="row2">
          <el-form-item
            prop="startDate"
            :rules="rules.startDate2"
            :label="$t(`popFillInInfomation.corporateInfo.startDate2`)"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.startDate"
              type="date"
              v-bind="otherBind"
              placeholder="请选择"
              :editable="!system.isPhone"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <text-input
            :otherBind="otherBind"
            prop="startDate"
            :rules="rules.startDate"
            v-model="form.startDate"
            :label="$t(`popFillInInfomation.corporateInfo.startDate2`)"
          ></text-input> -->
        </el-col>
        <el-col v-bind="row2">
          <div class="width100">
            若为中国香港主体，请选择公司注册证书(CR)上的注册登记日期
          </div>
        </el-col>
        <!--注册登记有效期  -->
        <el-col v-bind="row2">
          <el-form-item
            prop="validPeriod"
            :rules="rules.validPeriod2"
            :label="$t(`popFillInInfomation.corporateInfo.validPeriod2`)"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.validPeriod"
              type="date"
              v-bind="otherBind"
              placeholder="请选择"
              :editable="!system.isPhone"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <text-input
            :otherBind="otherBind"
            prop="validPeriod"
            :rules="rules.validPeriod"
            v-model="form.validPeriod"
            :label="$t(`popFillInInfomation.corporateInfo.validPeriod2`)"
          ></text-input> -->
        </el-col>
        <el-col v-bind="row2">
          <div class="width100 width_line">
            <div>1.若注册登记证明文件无有效期，可勾选【长期】选项</div>
            <div>2.若为中国香港主体，请选择商业登记证(BR)上的届满日期</div>
          </div>
        </el-col>
        <!--经营地址  -->
        <el-col v-bind="row1">
          <text-input
            :otherBind="otherBind"
            prop="address"
            :rules="rules.address2"
            v-model="form.address"
            :label="$t(`popFillInInfomation.corporateInfo.address2`)"
          ></text-input>
        </el-col>
        <!--境外公司证明  -->
        <el-col v-bind="row2">
          <!-- 多文件上传下载 -->
          <upload-file-more
            prop="overseasBusinessCertificationUrl"
            :tips="tips"
            :rules="rules.overseasBusinessCertificationUrl"
            :dataList.sync="form.overseasBusinessCertificationUrl"
            :label="
              $t(
                `popFillInInfomation.corporateInfo.overseasBusinessCertificationUrl`
              )
            "
          >
          </upload-file-more>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
//公共组件导入处;
import uplodeInput3 from "@/views/formComponents/uplodeInput3";
import textInput from "@/views/formComponents/textInput";
// import registrationCertificate from "./registrationcertificate";
import uploadFileMore from "@/views/formComponents/uploadfilemore";
import uploadMore from "@/views/formComponents/uploadmore";
//子组件导入处;

//方法导入处;

export default {
  components: {
    uplodeInput3,
    textInput,
    // registrationCertificate,
    uploadFileMore,
    uploadMore
  },
  // model: {
  //   prop: "formData",
  //   event: "update"
  // },
  props: {
    row1: [Object],
    row2: [Object],
    gutter: [Number],
    formData: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    form: {
      get: function() {
        return this.formData;
      },
      set: function(value) {
        this.$emit("update:formData", value);
      }
    }
  },
  data() {
    return {
      addressList: [],
      cascaderProps: {
        label: "areaName",
        value: "areaName",
        children: "childList"
      },
      otherBind: {
        size: "medium"
      },
      tips: ["请上传公司海外银行开户证明或银行对账单及翻译件"]
    };
  },
  methods: {
    uplodeChange(value) {
      console.log("处理执照照片:", value);
      this.form = { ...this.form, ...value };
      this.$parent.$parent.$parent.$refs["applyAgentForm"].validateField(
        Object.keys(value)[0]
      );
    },
    getBusinessLicenseUrlInfo(value) {
      this.$message({
        message: "请稍等，识别中...",
        type: "none",
        // duration: 0,
        customClass: "zidingyiel_message"
      });
      this.$request("/api/front/daemon/ocr/ocrBusinessLicense", {
        data: {
          ocrImage: Object.values(value)[0]
        },
        method: "POST"
      })
        .then(res => {
          if (res.code == 1 && res?.data) {
            let data = res.data;
            this.form.companyName = data.name;
            this.form.creditCode = data.reg_num;
            this.form.address = data.address;
            // this.form.registeredCapital = data.capital;
            if (data.establish_date) {
              data.establish_date = data.establish_date.match(
                /(\d{4})(\d{2})(\d{2})/
              );
              data.establish_date.shift();
              this.form.startDate = data.establish_date.join("-");
            }
            if (data.valid_period) {
              if (data.valid_period == "29991231") {
                this.form.longPeriod = 1;
                this.form.validPeriod = null;
              } else {
                data.valid_period = data.valid_period.match(
                  /(\d{4})(\d{2})(\d{2})/
                );
                data.valid_period.shift();
                this.form.validPeriod = data.valid_period.join("-");
                this.form.longPeriod = 0;
              }
            }
            this.uplodeChange(value);
            // this.getParsingAreaData(data.address);
          } else {
            console.log("不是营业执照照片");
            this.$message({
              message: "未识别出营业执照图片，请重新上传！",
              type: "error"
            });
            this.uplodeChange(value);
          }
          console.log(res);
        })
        .catch(() => {});
    },
    // // 解析地址
    // getParsingAreaData(addr) {
    //   this.$request(
    //     "/api/front/order/address/consigneeInformation?addr=" +
    //       addr +
    //       "黄毛毛18888888888",
    //     {
    //       method: "GET",
    //       data: addr
    //     }
    //   )
    //     .then(res => {
    //       res = res?.data || {};
    //       let { province = null, city = null, district = null } = res;
    //       if (province && city && district) {
    //         this.form.zhuchedizhi = [res.province, res.city, res.district];
    //         this.form.address = this.form.address.replace(
    //           this.form.zhuchedizhi.join(""),
    //           ""
    //         );
    //         this.$nextTick(() => {
    //           this.handleChange();
    //         });
    //       }
    //     })
    //     .catch(() => {});
    // },
    // getAreaData() {
    //   this.$request("/api/fadmin/order/address/getAllAddress", {
    //     method: "GET"
    //   })
    //     .then(res => {
    //       this.addressList = res.data;
    //     })
    //     .catch(() => {});
    // },
    // // 省市区选择后的联动处理
    // handleChange() {
    //   let nodes = this.$refs.zhuchedizhi.getCheckedNodes();
    //   console.log(nodes);
    //   if (nodes.length == 0) {
    //     nodes = [];
    //   } else {
    //     nodes = nodes[0];
    //   }
    //   let path = [...new Set(nodes.pathNodes)];
    //   path = path.map(i => i.data.areaCode);
    //   let { pathLabels = [] } = nodes;
    //   let [provinceId = null, cityId = null, districtId = null] = path;
    //   let [
    //     provinceName = null,
    //     cityName = null,
    //     districtName = null
    //   ] = pathLabels;
    //   this.form.provinceId = provinceId;
    //   this.form.cityId = cityId;
    //   this.form.districtId = districtId;
    //   this.form.provinceName = provinceName;
    //   this.form.cityName = cityName;
    //   this.form.districtName = districtName;
    // },
    // addressListFocus() {
    //   console.log(arguments);
    //   setTimeout(() => {
    //     document
    //       .getElementsByClassName("el-cascader__dropdown")
    //       .forEach(element => {
    //         console.log(element.style.left);
    //         element.style.left = "0px";
    //       });
    //   }, 0);
    // },
    changqiEvent() {
      this.$refs["validPeriod"].resetField();
      // this.$parent.$parent.$parent.$refs["applyAgentForm"].resetFields();
    }
  },
  mounted() {
    // this.getAreaData();
  },
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.content_box {
  border: 1px solid rgba(18, 18, 18, 0.05);
  border-radius: 2px;
  padding: 24px;
  margin-top: 12px;
}
::v-deep .el-cascader--medium,
.el-date-editor {
  width: 100%;
}
.yihang {
  display: flex;
  align-items: center;
  gap: 14px;
}
.wap {
  .content_box {
    margin-top: 24px;
  }
}
.width100 {
  width: 100%;
  height: 62px;
  line-height: 40px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.width_line {
  line-height: 22px;
  height: auto;
}
</style>
