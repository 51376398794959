<template>
  <div class="main_containter" :class="system.isPhone ? 'wap' : 'pc'">
    <azgCard>
      <template #title>
        <div class="jieguo_box">
          <img class="icon" :src="jieguo[form.status].icon" />
          <span class="text font600w" :class="jieguo[form.status].color">{{
            jieguo[form.status].text
          }}</span>
        </div>
      </template>
      <div class="list_box">
        <!-- <ul v-if="form.status == 1" class="shenhezhong">
          <li>
            <div class="div_title">
              {{ $t(`popFillInInfomation.corporateInfo.companyName`) }}：
            </div>
            <div class="div_text">{{ form.companyName }}</div>
          </li>
          <li>
            <div class="div_title">
              {{ $t(`popFillInInfomation.corporateInfo.creditCode`) }}：
            </div>
            <div class="div_text">{{ form.creditCode }}</div>
          </li>
          <li>
            <div class="div_title">
              {{ $t(`popFillInInfomation.legalPersonInfo.idcardName`) }}：
            </div>
            <div class="div_text">{{ form.idcardName }}</div>
          </li>
          <li>
            <div class="div_title">
              {{ $t(`popFillInInfomation.legalPersonInfo.idcardNumber`) }}：
            </div>
            <div class="div_text">{{ form.idcardNumber }}</div>
          </li>
          <li>
            <div class="div_title">
              {{ $t(`popFillInInfomation.administratorInfo.phone`) }}：
            </div>
            <div class="div_text">{{ form.adminMobile }}</div>
          </li>
        </ul> -->
        <div v-if="form.status == 1" class="shenhezhong1">
          <div class="sh_title">
            {{ $t(`popFillInInfomation.result.resultStatus11`) }}
          </div>
          <div>{{ $t(`popFillInInfomation.result.resultStatus12`) }}</div>
          <div>{{ $t(`popFillInInfomation.result.resultStatus13`) }}</div>
          <div>
            <el-image
              style="width: 200px; height: 200px"
              :src="$t(`popFillInInfomation.result.resultStatus14`)"
            ></el-image>
          </div>
          <div>{{ $t(`popFillInInfomation.result.resultStatus15`) }}</div>
        </div>
        <div v-else-if="form.status == 2" class="tongguo_box">
          <div>{{ $t(`popFillInInfomation.result.resultOk`) }}</div>
          <div class="call_us">
            <a href="tel:4001886999">
              {{ $t(`popFillInInfomation.result.resultOkCallUs`) }}</a
            >
          </div>
        </div>
        <div v-else v-html="form.rejectReason"></div>
      </div>
    </azgCard>
  </div>
</template>

<script>
//公共组件导入处;
import azgCard from "@/views/publicComponents/azgCard";
//子组件导入处;

//方法导入处;

export default {
  components: {
    azgCard
  },
  data() {
    return {
      jieguo: {
        1: {
          color: "daishenhe",
          text: "待平台审核",
          icon: require("@/assets/img/pop/icon_daishenhe.jpg")
        },
        2: {
          color: "tongguo",
          text: "审核通过",
          icon: require("@/assets/img/pop/icon_guo.jpg")
        },
        3: {
          color: "weitonttuo",
          text: "审核未通过",
          icon: require("@/assets/img/pop/icon_weiguo.jpg")
        }
      }
    };
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    form: {
      get: function() {
        return this.formData;
      },
      set: function(value) {
        this.$emit("update:formData", value);
      }
    }
  },
  methods: {},
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.shenhezhong {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: rgba(18, 18, 18, 0.85);
  li {
    line-height: 40px;
    display: flex;
    .div_title {
      flex: 1;
      text-align: right;
    }
    .div_text {
      flex: 1;
      text-align: left;
    }
  }
}
.icon {
  width: calc(100vw * 3.2 / 192);
  height: calc(100vw * 3.2 / 192);
  margin-right: 15px;
}
.text {
  font-size: calc(100vw * 2.4 / 192);
}
.jieguo_box {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.tongguo_box {
  margin: 0 30%;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(18, 18, 18, 0.85);
  line-height: 24px;
  .call_us {
    a {
      color: rgba(18, 18, 18, 0.65);
    }
    margin-top: 12px;
  }
}
.daishenhe {
  color: #006af8;
}
.weitonttuo {
  color: #f5222d;
}
.tongguo {
  color: #42b708;
}
.pc {
}
.wap {
  .icon {
    width: calc(100vw * 2.4 / 37.5);
    height: calc(100vw * 2.4 / 37.5);
    margin-right: 10px;
  }
  .text {
    font-size: calc(100vw * 1.8 / 37.5);
  }
  .shenhezhong {
    li {
      .div_title {
        flex: 0.8;
        text-align: right;
      }
      .div_text {
        flex: 1;
        text-align: left;
      }
    }
  }
  .tongguo_box {
    margin: 0 24px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: rgba(18, 18, 18, 0.85);
    line-height: 24px;
    .call_us {
      margin-top: 12px;
    }
  }
}
.shenhezhong1 {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: rgba(18, 18, 18, 0.85);
  line-height: 40px;
  .sh_title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}
</style>
