<template>
  <div>
    <div :class="system.isPhone ? 'wap' : 'pc'">
      <el-form
        label-suffix="："
        ref="applyAgentForm"
        :label-position="system.isPhone ? 'top' : 'right'"
        label-width="auto"
        :model="form"
        :rules="rules"
      >
        <azgCard
          t="POP商家入驻申请"
          :title="$t(`popFillInInfomation.BOX_TITLE`)"
        >
          <stepBar :active="getStepBarActive" :formData="form"></stepBar>
        </azgCard>
        <!-- 审核结果信息 -->
        <resultInfo
          v-if="[1, 2, 3].includes(form.status)"
          :formData.sync="form"
        ></resultInfo>

        <template v-if="![1, 2].includes(form.status)">
          <azgCard
            t="企业信息"
            :title="$t(`popFillInInfomation.corporateInfo.BOX_TITLE`)"
          >
            <corporateInformation
              :rules="rules"
              :formData.sync="form"
              :row2="row2"
              :row1="row1"
              :gutter="gutter"
            ></corporateInformation>
          </azgCard>

          <azgCard
            v-if="form.subjectType == 1"
            t="法人信息"
            :title="$t(`popFillInInfomation.legalPersonInfo.BOX_TITLE`)"
          >
            <legalPersonInformation
              :rules="rules"
              :formData.sync="form"
              :row2="row2"
              :gutter="gutter"
            ></legalPersonInformation>
          </azgCard>
          <!-- 境外授权信息 -->
          <azgCard
            v-if="form.subjectType == 2"
            t="境外授权信息"
            :title="$t(`popFillInInfomation.authorizationInfo.BOX_TITLE`)"
          >
            <authorizatIoninfo
              :rules="rules"
              :formData.sync="form"
              :row2="row2"
              :row1="row1"
              :gutter="gutter"
            ></authorizatIoninfo>
          </azgCard>

          <!-- <azgCard
            t="管理员信息"
            :title="$t(`popFillInInfomation.administratorInfo.BOX_TITLE`)"
          >
            <administratorInformation
              :rules="rules"
              :formData.sync="form"
              :row2="row2"
              :gutter="gutter"
            ></administratorInformation>
          </azgCard> -->

          <azgCard
            t="店铺类型"
            :title="$t(`popFillInInfomation.BOX_TITLE_POP`)"
          >
            <shopType
              :rules="rules"
              :formData.sync="form"
              :row2="row2"
              :gutter="gutter"
            ></shopType>
          </azgCard>

          <azgCard
            t="品牌与资质"
            :title="$t(`popFillInInfomation.brand.BOX_TITLE`)"
          >
            <div
              v-for="(item, index) in form.popStoreBrandList"
              :key="item.pinpaiweiyiID"
            >
              <div
                v-if="form.storeType == 4 || form.storeType == 5 || index == 0"
              >
                <brandInfoNew
                  :rules="rules"
                  :itemIndex="index"
                  :formData.sync="form"
                  :row2="row2"
                  :gutter="gutter"
                  @validate="
                    (index) =>
                      $nextTick(() => {
                        $refs.applyAgentForm.validateField([
                          `popStoreBrandList.${index}.brandName`,
                          `popStoreBrandList.${index}.trademarkSn`,
                          `popStoreBrandList.${index}.trademarkSnOwner`,
                          `popStoreBrandList.${index}.certificationPicUrl`,
                          `popStoreBrandList.${index}.brandLogo`,
                          `popStoreBrandList.${index}.certificationTime`,
                        ]);
                      })
                  "
                ></brandInfoNew>
                <!-- 商标信息 -->
                <!-- <brandInfo
                  :rules="rules"
                  :itemIndex="index"
                  :formData.sync="form"
                  :row2="row2"
                  :row1="row1"
                  :gutter="gutter"
                ></brandInfo> -->
                <!-- 商标受理通知书信息 -->
                <!-- <brandInfoAcceptance
                  :rules="rules"
                  :itemIndex="index"
                  :formData.sync="form"
                  :row2="row2"
                  :gutter="gutter"
                ></brandInfoAcceptance> -->

                <!-- 商品授权书信息 -->
                <!-- <brandInfoAuthorization
                  :rules="rules"
                  :itemIndex="index"
                  :formData.sync="form"
                  :row2="row2"
                  :gutter="gutter"
                ></brandInfoAuthorization> -->

                <!-- 其他资质证明 -->
                <!-- <brandInfoOtherInfo
                  :rules="rules"
                  :itemIndex="index"
                  :formData.sync="form"
                  :row2="row2"
                  :gutter="gutter"
                ></brandInfoOtherInfo> -->

                <div
                  v-if="form.storeType == 4 || form.storeType == 5"
                  class="line_but_list"
                >
                  <el-button
                    v-if="index == form.popStoreBrandList.length - 1"
                    @click="addBrandAndCategory"
                    size="mini"
                    type="primary"
                    >新增品牌</el-button
                  >
                  <el-button
                    v-if="form.popStoreBrandList.length > 1"
                    @click="deleteBrandAndCategory(index)"
                    size="mini"
                    >删除品牌</el-button
                  >
                </div>
                <el-divider
                  v-if="
                    (form.storeType == 4 || form.storeType == 5) &&
                      index != form.popStoreBrandList.length - 1
                  "
                  >下一个品牌信息区</el-divider
                >
              </div>
            </div>
          </azgCard>
          <azgCard
            t="店铺信息"
            :title="$t(`popFillInInfomation.storeInfo.BOX_TITLE`)"
          >
            <shopInformation
              :rules="rules"
              :formData.sync="form"
              :row2="row2"
              :row1="row1"
              :gutter="gutter"
            ></shopInformation>
          </azgCard>
          <azgCard title="其他">
            <text-input
              prop="memo"
              inputType="textarea"
              class="text_line"
              v-model="form.memo"
              :maxlength="300"
              p="请输入0-300字"
              :label="$t(`popFillInInfomation.other.memo`)"
              :otherBind="{ autosize: { minRows: 6, maxRows: 8 } }"
            ></text-input>
          </azgCard>

          <!-- <uplode-input
          prop="idcardFrontUrl"
          :label="$t('applyAgent.' + whoApply + '.idcardALL')"
          keyName="idcardFrontUrl"
          @uplodeChange="uplodeChange"
        ></uplode-input>
        <uplode-input
          prop="idcardBackUrl"
          keyName="idcardBackUrl"
          @uplodeChange="uplodeChange"
        ></uplode-input>
        <uplode-input
          prop="idcardHandUrl"
          keyName="idcardHandUrl"
          @uplodeChange="uplodeChange"
        ></uplode-input> -->
          <div class="zanwei"></div>
          <div class="footer_box">
            <el-checkbox v-model="agreeWithTheProtocol">
              {{ $t(`popFillInInfomation.submitText.xieyi1`)
              }}<a
                :href="'pop_ruzhuxieyi.html?t=' + new Date().getTime()"
                target="_blank"
                class="xieyi"
              >
                {{ $t(`popFillInInfomation.submitText.xieyi2`) }}</a
              >
            </el-checkbox>
            <el-button
              class="next_but "
              type="primary"
              @click="submitForm('applyAgentForm')"
              >{{ $t("popFillInInfomation.submitText.submit") }}</el-button
            >
          </div>
        </template>
      </el-form>
    </div>
    <el-dialog
      class="yzmjiaoyan"
      :close-on-click-modal="false"
      :show-close="false"
      title="安全校验："
      :visible.sync="dialogVisible"
      width="60%"
      top="35vh"
      :before-close="handleClose"
    >
      <ul>
        <li class="yzm_phone">请验证管理员手机号：{{ form.adminMobile }}</li>
        <li class="yzm_content">
          <div class="lable">请输入验证码：</div>
          <el-input v-model="form.adminMobileCode">
            <template slot="suffix">
              <getCodeBut :phone="form.adminMobile" kind="PopWebRegister">
                <template #default="{info}">
                  <el-button :disabled="!info.canDo" type="primary">{{
                    info.text
                  }}</el-button>
                </template>
              </getCodeBut>
            </template>
          </el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          :disabled="
            !(form.adminMobileCode && form.adminMobileCode.length == 6)
          "
          type="primary"
          @click="tijiao"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import azgCard from "@/views/publicComponents/azgCard";
import textInput from "@/views/formComponents/textInput";
import stepBar from "./stepBar";
import corporateInformation from "./corporateInformation";
import legalPersonInformation from "./legalPersonInformation";
import authorizatIoninfo from "./authorizationinfo"; //跨境授权信息
// import administratorInformation from "./administratorInformation";
import shopType from "./shopType";
import shopInformation from "./shopInformation";
// import categoryInfo from "./categoryInfo";
// import brandInfo from "./brandInfo";
import brandInfoNew from "./brandInfoNew";
// import brandInfoAcceptance from "./brandInfoAcceptance";
// import brandInfoAuthorization from "./brandInfoAuthorization";
// import brandInfoOtherInfo from "./brandInfoOtherInfo";
import resultInfo from "./resultInfo";
import getCodeBut from "@/views/formComponents/getCodeBut";
// import uplodeInput from "@/views/formComponents/uplodeInput3";

import { verifyPhone } from "@/utils/pattern.js";
export default {
  name: "popApplyForm",
  components: {
    stepBar,
    corporateInformation,
    legalPersonInformation,
    // administratorInformation,
    shopInformation,
    shopType,
    // categoryInfo,
    // brandInfo,
    brandInfoNew,
    // brandInfoAcceptance,
    // brandInfoAuthorization,
    // brandInfoOtherInfo,
    textInput,
    getCodeBut,
    resultInfo,
    // uplodeInput,
    azgCard,
    authorizatIoninfo,
  },
  data() {
    var phone = (rule, value, callback) => {
      if (!verifyPhone(value)) {
        callback(
          new Error(
            `${this.$t(`popFillInInfomation.${rule.labelName}`)}${this.$t(
              "nameInputPhone"
            )}`
          )
        );
      } else {
        callback();
      }
    };
    var email = (rule, value, callback) => {
      if (!value) {
        let errMsg = this.$t(`popFillInInfomation.${rule.labelName}`);
        // errMsg = `${errMsg}${this.$t("applyAgent.errMsg.emailnotallow")}`;  //正常中英文使用
        errMsg = `${errMsg}${this.$t(
          "popFillInInfomation.errMsg.emailnotallow"
        )}`; //临时使用
        callback(new Error(errMsg));
      } else {
        callback();
      }
    };
    var notNull = (rule, value, callback) => {
      if (value === "" || value === null || value === undefined) {
        let errMsg = this.$t(`popFillInInfomation.${rule.labelName}`);
        // errMsg = `${errMsg}${this.$t("applyAgent.errMsg.notNull")}`;  //正常中英文使用
        errMsg = `${errMsg}${this.$t("popFillInInfomation.errMsg.notNull")}`; //临时使用
        callback(new Error(errMsg));
      } else {
        callback();
      }
    };
    var img = (rule, value, callback) => {
      // console.log(rule, value, callback);
      let newValue = this.form[rule.field];
      if (rule.itemIndex || rule.itemIndex === 0) {
        let arr = rule.field.split(`.${rule.itemIndex}.`);
        newValue = this.form[arr[0]][rule.itemIndex][arr[1]];
      }

      if (!newValue || newValue.indexOf("http") < 0) {
        let errMsg = this.$t(`popFillInInfomation.${rule.labelName}`);
        // errMsg = `${errMsg}${this.$t("applyAgent.errMsg.notNull")}`;  //正常中英文使用
        errMsg = `${errMsg}${this.$t("popFillInInfomation.errMsg.notNull")}`; //临时使用
        callback(new Error(errMsg));
      } else {
        callback();
      }
    };
    var imgMore = (rule, value, callback) => {
      let newValue = [];
      newValue = this.form[rule.field];
      if (newValue.length == 0) {
        let errMsg = this.$t(`popFillInInfomation.${rule.labelName}`);
        errMsg = `${errMsg}${this.$t("popFillInInfomation.errMsg.notNull")}`; //临时使用
        callback(new Error(errMsg));
      } else {
        callback();
      }
    };
    var file = (rule, value, callback) => {
      let field = rule.field;
      let newValue = [];
      if (field.indexOf(".") > -1) {
        const splitListData = field.split(".")[0];
        const splitListDataIndex = field.split(".")[1];
        const splitListDataName = field.split(".")[2];
        newValue = this.form[splitListData][splitListDataIndex][
          splitListDataName
        ];
      } else {
        newValue = this.form[field];
      }
      if (!newValue || newValue.length == 0) {
        let errMsg = this.$t(`popFillInInfomation.${rule.labelName}`);
        // errMsg = `${errMsg}${this.$t("applyAgent.errMsg.notNull")}`;  //正常中英文使用
        errMsg = `${errMsg}${this.$t("popFillInInfomation.errMsg.notNull")}`; //临时使用
        callback(new Error(errMsg));
      } else {
        callback();
      }
    };

    return {
      brandActiveKey: "1", //多品牌选中下标
      dialogVisible: false, //验证码弹窗开关
      agreeWithTheProtocol: false, //同意入住协议
      row2: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 12,
        xl: 12,
      },
      row1: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
      },
      gutter: 32,
      areaData: [], // 省市数据
      cityData: [], //市数据
      form: {
        businessLine: 1, //写死值，必要的
        registerCertificationUrl: [], //注册登记证明
        registerCertificationUrlTipList: [], //注册登记证明
        overseasBusinessCertificationUrl: [], //境外公司证明
        authorizationUrl: [], //境外公司证明
        kind: "POP", //写死值，必要的
        adminMobileKind: "PopWebRegister", //写死值，必要的
        // 公司信息
        status: null, //1:已提交 2：通过 3：拒绝
        subjectType: 1, //主体类型
        businessLicenseUrl: null, //营业执照图片
        companyName: null, //公司名称
        creditCode: null, //统一社会信用代码
        zhuchedizhi: null, //注册地址(提交时拆分为provinceId，cityId，districtId)
        provinceId: null, //省编码
        cityId: null, //市编码
        districtId: null, //区编码
        provinceName: null, //省
        cityName: null, //市
        districtName: null, //区
        address: null, //详细地址
        startDate: null, //成立时间
        validPeriod: null, //经营期限
        longPeriod: null, //经营期限是否长期:0 不是 1 是长期
        registeredCapital: null, //注册资本

        // 法人信息
        idcardFrontUrl: null, //身份证正面(人像面)
        idcardBackUrl: null, //身份证反面(国徽面)
        idcardName: null, //姓名
        idcardNumber: null, //身份证号
        idcardStartDate: null, //身份证有效期起
        idcardEndDate: null, //身份证有效期止
        idcardLongPeriod: null, //身份证有效期是否长期： 0 不是 1 是长期

        // 管理员信息
        adminName: null, //姓名
        adminEmail: null, //邮箱
        adminMobile: null, //手机号
        adminMobileCode: null, //验证码

        //  店铺类型
        storeType: 1, //旗舰店，2-官方自营旗舰店  1-旗舰店 ，3转卖店，4专营店
        brandLogo: null, //店铺logo(原应该用shopLogo字段，因各端改动涉及太广，还是沿用brandLogo替代shopLogo传给后端)

        //  品牌与资质》 经营类目
        categoryJson: null, //经营类目path
        categoryArray: null, //经营类目文本
        categoryJson1: [], //兼容会南用的，categoryJson的变版本

        popStoreBrandList: [
          {
            // pinpaiweiyiID: `1`,
            //  品牌与资质》 商标信息
            // brandName: null, //品牌名称
            // storeName: null, //店铺名称
            // mainlandTrademarkLicense: 1, //是否持有中国大陆商标注册证/申请书（0:否,1:是）
            // trademarkSn: null, //商标注册号
            // trademarkSnOwner: null, //商标注册人
            // brandLogo: null, //品牌LOGO
            // productSource: null, //商品原产地：1:进口 2:非进口
            // companyKind: null, //公司类型 1:经营企业 2:生产企业
            // //  品牌与资质》 商标注册证书/商标申请受理通知书
            // certificationChange: null, //商标是否变更: 0 否  1:是
            // certificationPicUrl: null, //资质文件
            // certificationTime: null, //有效期至
            // //  品牌与资质》 商标授权书
            // isTrademarkOwner: null, //是否为品牌商： 0 不是 1 是
            // authorizedCompanyName: null, //授权公司名
            // salesAuthorizationLetterUrl: null, //销售授权书
            // salesAuthorizationLetterDate: null, //有效期
            // //  品牌与资质》 其他资质证明
            // trademarkOwnerOthers: null, //图片集合
            // trademarkOwnerOthersList: [] //trademarkOwnerOthers的数组对象结构，提交时移除
          },
        ],

        //  其它
        memo: null, //备注信息
      },
      canSendCode: "false",
      rules: {
        subjectType: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "corporateInfo.subjectType",
          },
        ],
        registerCertificationUrl: [
          {
            required: true,
            // validator: notNull,
            validator: imgMore,
            labelName: "corporateInfo.registerCertificationUrl",
          },
        ],
        businessLicenseUrl: [
          {
            required: true,
            validator: img,
            labelName: "corporateInfo.businessLicenseUrl",
          },
        ],
        companyName: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "corporateInfo.companyName",
          },
        ],
        creditCode: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "corporateInfo.creditCode",
          },
        ],
        creditCode2: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "corporateInfo.creditCode2",
          },
        ],
        zhuchedizhi: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "corporateInfo.zhuchedizhi",
          },
        ],
        address: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "corporateInfo.address",
          },
        ],
        startDate: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "corporateInfo.startDate",
          },
        ],
        startDate2: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "corporateInfo.startDate2",
          },
        ],
        validPeriod: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "corporateInfo.validPeriod",
          },
        ],
        validPeriod2: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "corporateInfo.validPeriod2",
          },
        ],
        longPeriod: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "corporateInfo.validPeriod",
          },
        ],
        registeredCapital: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "corporateInfo.registeredCapital",
          },
        ],
        overseasBusinessCertificationUrl: [
          {
            required: true,
            validator: file,
            labelName: "corporateInfo.overseasBusinessCertificationUrl",
          },
        ],

        // 法人信息

        idcardFrontUrl: [
          {
            required: true,
            validator: img,
            labelName: "legalPersonInfo.idcardFrontUrl",
          },
        ],
        idcardBackUrl: [
          {
            required: true,
            validator: img,
            labelName: "legalPersonInfo.idcardBackUrl",
          },
        ],
        idcardName: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "legalPersonInfo.idcardName",
          },
        ],
        idcardNumber: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "legalPersonInfo.idcardNumber",
          },
        ],
        idcardStartDate: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "legalPersonInfo.idcardStartDate",
          },
        ],
        idcardEndDate: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "legalPersonInfo.idcardEndDate",
          },
        ],
        idcardLongPeriod: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "legalPersonInfo.idcardEndDate",
          },
        ],
        // 境外授权信息
        authorizationUrl: [
          {
            required: true,
            validator: file,
            labelName: "authorizationInfo.authorizationUrl",
          },
        ],
        authorizationPeriod1: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "authorizationInfo.authorizationPeriod",
          },
        ],
        authorizationPeriod: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.authorizationPeriod",
          },
        ],
        authorizationLongPeriod: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.authorizationPeriod",
          },
        ],
        authorizerLocationType: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "authorizationInfo.authorizerLocationType",
          },
        ],
        authorizerCredentialsPeriod: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "authorizationInfo.authorizerCredentialsPeriod",
          },
        ],

        // 管理员信息
        adminName: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "administratorInfo.name",
          },
        ],
        adminEmail: [
          {
            required: true,
            validator: email,
            trigger: "blur",
            labelName: "administratorInfo.email",
          },
        ],
        adminMobile: [
          {
            required: true,
            validator: phone,
            trigger: "blur",
            labelName: "administratorInfo.phone",
          },
        ],

        // 店铺类型》店铺LOGO
        shopLogo: [
          {
            required: true,
            validator: img,
            labelName: "popShopInfoObj.shopLogo",
          },
        ],
        brandType: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.brandType",
          },
        ],

        //  品牌与资质》 经营类目
        categoryJson: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "brand.categoryInfo.category",
          },
        ],

        //  品牌与资质》 商标信息
        brandName: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "brand.brandInfo.brandName",
          },
        ],
        storeName: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "brand.brandInfo.storeName",
          },
        ],
        mainlandTrademarkLicense: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.brandInfo.mainlandTrademarkLicense",
          },
        ],

        trademarkSn: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "brand.brandInfo.trademarkSn",
          },
        ],
        trademarkSnOwner: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "brand.brandInfo.trademarkSnOwner",
          },
        ],
        brandLogo: [
          {
            required: true,
            validator: img,
            labelName: "brand.brandInfo.brandLogo",
          },
        ],
        brandQualificationUrl: [
          {
            required: true,
            validator: file,
            labelName: "brand.brandQualificationUrl",
          },
        ],
        productSource: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.brandInfo.productSource",
          },
        ],
        companyKind: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.brandInfo.companyKind",
          },
        ],

        //  品牌与资质》 商标注册证书/商标申请受理通知书

        certificationChange: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.registration.certificationChange",
          },
        ],
        certificationPicUrl: [
          {
            required: true,
            validator: img,
            labelName: "brand.certificationPicUrl",
          },
        ],
        certificationTime: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.registration.certificationTime",
          },
        ],

        //  品牌与资质》 商标授权书

        isTrademarkOwner: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "brand.authorization.isTrademarkOwner",
          },
        ],
        authorizedCompanyName: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "brand.authorization.authorizedCompanyName",
          },
        ],
        authorizationLevel: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "brand.authorizationLevel",
          },
        ],
        salesAuthorizationLetterDate: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "brand.authorization.salesAuthorizationLetterDate",
          },
        ],
        salesAuthorizationLetterUrl: [
          {
            required: true,
            validator: img,
            labelName: "brand.authorization.salesAuthorizationLetterUrl",
          },
        ],
        idcardHandUrl: [{ required: true, validator: img }],
        // 店铺信息
        tradeType: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "storeInfo.tradeType",
          },
        ],
        warehousingMode: [
          {
            required: true,
            validator: notNull,
            trigger: "change",
            labelName: "storeInfo.warehousingMode",
          },
        ],
        warehousingMemo: [
          {
            required: true,
            validator: notNull,
            trigger: "blur",
            labelName: "storeInfo.warehousingMemo",
          },
        ],
      },
    };
  },
  computed: {
    getStepBarActive() {
      if ([null, 3].includes(this.form.status)) {
        return 0;
      } else {
        return this.form.status;
      }
    },
    getShopTypeInfo() {
      let arr = this.$t(`popFillInInfomation.popShopInfo`);
      let info = arr.filter((item) => item.value == this.form.storeType);
      return info[0];
    },
  },
  mounted() {
    this.getApplyInfo();
  },
  methods: {
    deleteBrandAndCategory(index) {
      let tabs = this.form.popStoreBrandList;

      this.form.popStoreBrandList = tabs.filter(
        (tab, tabIndex) => tabIndex !== index
      );
      // this.$message({
      //   message: "已移除",
      //   type: "none",
      //   customClass: "zidingyiel_message"
      // });
    },
    addBrandAndCategory(type) {
      let list = type == "init" ? [] : this.form.popStoreBrandList;
      // let l = "1";
      // if (list.length > 0) {
      //   l = Number(l) + Number(list[list.length - 1].pinpaiweiyiID);
      // }
      list.push({
        // // pinpaiweiyiID: `${l}`,
        // //  品牌与资质》 商标信息
        // brandName: null, //品牌名称
        // storeName: null, //店铺名称
        // mainlandTrademarkLicense: 1, //是否持有中国大陆商标注册证/申请书（0:否,1:是）
        // trademarkSn: null, //商标注册号
        // trademarkSnOwner: null, //商标注册人
        // brandLogo: null, //品牌LOGO
        // productSource: null, //商品原产地：1:进口 2:非进口
        // companyKind: null, //公司类型 1:经营企业 2:生产企业
        // //  品牌与资质》 商标注册证书/商标申请受理通知书
        // certificationChange: null, //商标是否变更: 0 否  1:是
        // certificationPicUrl: null, //资质文件
        // certificationTime: null, //有效期至
        // //  品牌与资质》 商标授权书
        // isTrademarkOwner: null, //是否为品牌商： 0 不是 1 是
        // authorizedCompanyName: null, //授权公司名
        // salesAuthorizationLetterUrl: null, //销售授权书
        // salesAuthorizationLetterDate: null, //有效期
        // //  品牌与资质》 其他资质证明
        // trademarkOwnerOthers: null, //图片集合
        // trademarkOwnerOthersList: [] //trademarkOwnerOthers的数组对象结构，提交时移除
      });
      if (type == "init") {
        return list;
      } else {
        this.form.popStoreBrandList = list;
      }

      // this.brandActiveKey = `${l}`;
    },
    validate() {
      console.log(arguments);
    },
    areaChangeEvent(areaName) {
      this.form.city = "";
      this.$nextTick();
      this.areaData.map((item) => {
        if (item.areaName === areaName) {
          this.cityData = item.childList;
        }
      });
    },
    submitForm(formName) {
      if (!this.agreeWithTheProtocol) {
        this.$message({
          message: "请先同意商家入住协议",
          type: "error",
          customClass: "zidingyiel_message",
        });
        return;
      }
      console.log(JSON.parse(JSON.stringify(this.form)), 666);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //接口校验
          this.validateApi();
        } else {
          // console.log(1111);
          // console.log(valid);
          this.$message({
            message: "有必填项未填写",
            type: "warning",
          });
          return false;
        }
      });
    },

    //数据处理
    handleData() {
      let data = JSON.parse(JSON.stringify(this.form));
      // 提交前的数据处理
      delete data.zhuchedizhi;
      // 经营期限是否长期的处理
      if (data.longPeriod != 1) {
        data.longPeriod = 0;
      } else {
        data.validPeriod = null;
      }
      // 身份证有效期是否长期的处理
      if (data.idcardLongPeriod != 1) {
        data.idcardLongPeriod = 0;
      } else {
        data.idcardEndDate = null;
      }

      //非专营店的只保留一个品牌
      if (data.storeType != 4 || data.storeType != 5) {
        data.popStoreBrandList = [data.popStoreBrandList[0]];
      }
      //给店铺名称强制加上店铺类型
      data.storeName = `${data.storeName}${this.getShopTypeInfo.shopType}`;

      // ---------多品牌数据的循环处理开始
      data.popStoreBrandList.forEach((item) => {
        //是否持有中国大陆商标注册证/申请书的处理
        if (item.mainlandTrademarkLicense == 0) {
          item.trademarkSn = null;
        }

        // 是否为品牌商的处理
        // if (item.isTrademarkOwner == 1) {
        //   item.authorizedCompanyName = null;
        //   item.salesAuthorizationLetterUrl = null;
        //   item.salesAuthorizationLetterDate = null;
        // }

        // 商品原产地为进口下的公司类型处理
        // if (item.productSource == 1) {
        //   item.companyKind = null;
        // }
      });

      //---------多品牌数据的循环处理结束
      // 数据转换处理
      console.log("数据转换处理");
      data.categoryJson = [data.categoryJson ?? [], data.categoryJson1 ?? []];
      delete data.categoryJson1;
      return data;
    },

    validateApi() {
      const data = this.handleData();
      this.$request("/api/front/userInfoCheck/checkUserInfoCheckPop", {
        method: "POST",
        data,
      })
        .then((res) => {
          if (res.code === 1) {
            this.dialogVisible = true;
          }
        })
        .catch(() => {});
    },

    tijiao() {
      const data = this.handleData();
      //进行提交
      let url = "/api/front/userInfoCheck/addUserInfoCheckPop";
      if (data.status == 3) {
        data.status = 1; //状态重置为已提交
        url = "/api/front/userInfoCheck/updateUserInfoCheckPop";
      }
      this.$request(url, {
        method: "POST",
        data,
      })
        .then((res) => {
          if (res.code === 1) {
            // this.$router.replace({
            //   path: "applyAfter",
            //   query: { status: "daishenhe" }
            // });
            this.$message({
              message: "提交成功，等待审核",
              type: "none",
              customClass: "zidingyiel_message",
            });
            this.dialogVisible = false;
            this.getApplyInfo();
          }
        })
        .catch(() => {});
    },
    //验证码弹窗开关
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    // 获取填写的信息

    getApplyInfo() {
      this.$request("/api/front/userInfoCheck/loadUserInfoCheckPop", {
        method: "GET",
      })
        .then((res) => {
          if (res.code == 1 && res.data) {
            // // 强制到编辑
            // console.error("强制到编辑");
            // res.data.status = 3;
            // res.data.zhuchedizhi = [
            //   res.data.provinceName,
            //   res.data.cityName,
            //   res.data.districtName
            // ];
            //先把店铺类型保存下，方便下面处理店铺名称用
            this.form.storeType = res.data.storeType;

            // 一些转换
            if (res.data.categoryJson) {
              if (res.data.categoryJson.length == 1) {
                res.data.categoryJson1 = [];
              } else {
                res.data.categoryJson1 = res.data.categoryJson[1];
                res.data.categoryJson = res.data.categoryJson[0];
              }
            }
            res.data.registerCertificationUrl =
              res.data.registerCertificationUrl ?? [];
            res.data.overseasBusinessCertificationUrl =
              res.data.overseasBusinessCertificationUrl ?? [];
            res.data.authorizationUrl = res.data.authorizationUrl ?? [];

            //给店铺名称强制移除店铺类型
            res.data.storeName = res.data.storeName.slice(
              0,
              -this.getShopTypeInfo.shopType.length
            );
            if (res.data.popStoreBrandList.length == 0) {
              res.data.popStoreBrandList = [{}];
            }

            //多品牌数据的循环处理开始
            // if (
            //   !res.data.popStoreBrandList ||
            //   res.data.popStoreBrandList.length == 0
            // ) {
            //   res.data.popStoreBrandList = this.addBrandAndCategory("init");
            // } else {
            //   res.data.popStoreBrandList.forEach(item => {
            //     if (
            //       item.trademarkOwnerOthers &&
            //       item.trademarkOwnerOthers.length > 0
            //     ) {
            //       item.trademarkOwnerOthersList = item.trademarkOwnerOthers.map(
            //         i => {
            //           return {
            //             url: i
            //           };
            //         }
            //       );
            //     }
            //   });
            // }

            console.log(res, 5454554);
            this.form = { ...this.form, ...res.data };
            console.log(res);
          } else {
            console.log(22222);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    digui(arr) {
      return arr;
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-form-item__label {
  color: black;
}
.yzmjiaoyan {
  ::v-deep .el-dialog {
    max-width: 800px;
  }
}
.footer_box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 138px;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  flex-direction: column;
}
.xieyi {
  color: #006af8;
  font-size: 14px;
}
.xieyi_message {
  background-color: white !important;
}
.yzm_phone {
  margin-bottom: 24px;
}
.yzm_content {
  display: flex;
  width: 80%;
  max-width: 500px;
  ::v-deep .el-input__suffix {
    right: 0;
  }
  .lable {
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }
}
.add_css {
}
.line_but_list {
  padding: 12px 0;
  text-align: center;
}
.pc {
  .zanwei {
    height: 140px;
    width: 100%;
  }
  .next_but {
    width: 280px;
    height: 44px;
    background: #121212;
    border-radius: 2px;
    border: none;
  }
}

.wap {
  .zanwei {
    height: 140px;
    width: 100%;
  }
  .footer_box {
    height: 84px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .next_but {
    width: 280px;
    height: 34px;
    line-height: 34px;
    background: #121212;
    border-radius: 8px;
    border: none;
    padding: 0;
  }
}
</style>
