<template>
  <div :class="system.isPhone ? 'wap' : 'pc'">
    <div class="azg_card_all" :class="azgCardAllBoxClass">
      <div class="clb100 box_title" :class="titleClass">
        <div>
          <slot name="title"> {{ title }}</slot>
        </div>
        <div>
          <slot name="action"> </slot>
        </div>
      </div>
      <div class="line"></div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  components: {},
  data() {
    return {};
  },
  props: {
    boxClass: [String, Array],
    title: {
      type: String,
      default: null
    },
    // 是否显示边框
    border: {
      type: Boolean,
      default: false
    },
    // TITLE的class
    titleClass: {
      type: [Array, Object],
      default: () => []
    }
  },
  computed: {
    azgCardAllBoxClass() {
      let Obj = { azg_card_box_border: this.border };
      if (Array.isArray(this.boxClass)) {
        this.boxClass.map(i => (Obj[i] = true));
      } else if (this.boxClass) {
        Obj[this.boxClass] = true;
      }

      return Obj;
    }
  },
  methods: {},
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.pc {
  .azg_card_all {
    padding: 24px;
    margin: 24px;
    background-color: white;
    box-sizing: border-box;
    position: relative;
  }
  .azg_card_box_border {
    border: 1px solid rgba(18, 18, 18, 0.05);
    border-radius: 4px;
  }
  .box_title {
    font-size: calc(100vw * 2.4 / 192);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .line {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.wap {
  .azg_card_all {
    padding: 12px;
    margin-bottom: 12px;
    background-color: white;
    box-sizing: border-box;
  }
  .azg_card_box_border {
    border: 1px solid rgba(18, 18, 18, 0.05);
    border-radius: 4px;
  }
  .box_title {
    font-size: calc(100vw * 2.1 / 37.5);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .line {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
</style>
