<template>
  <div class="main_containter" :class="system.isPhone ? 'wap' : 'pc'">
    <div class="pd_box" style="padding-left: 26px;">
      <el-row :gutter="gutter">
        <!-- 店铺logo -->
        <el-col v-bind="row2">
          <uplode-input3
            prop="brandLogo"
            :label="$t(`popFillInInfomation.popShopInfoObj.shopLogo`)"
            keyName="brandLogo"
            @uplodeChange="uplodeChange"
            :value="form.brandLogo"
            :limit="2"
            :rules="rules.shopLogo"
          >
            <template #tip>
              <div>
                <ul>
                  <li v-for="item in getOtherTipList" :key="item">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </template>
          </uplode-input3>
        </el-col>
        <!-- 经营类目 -->
        <el-col v-bind="row2">
          <categoryInfo :formData.sync="form" :gutter="gutter"></categoryInfo>
        </el-col>
        <!-- 店铺名称 -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="storeName"
            v-model="form.storeName"
            :label="$t(`popFillInInfomation.brand.brandInfo.storeName`)"
            :rules="rules.storeName"
          >
            <template slot="append">
              {{ getSubInfo.shopType }}
            </template>
          </text-input>
        </el-col>
        <!-- 管理员姓名 -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="adminName"
            :rules="rules.adminName"
            v-model="form.adminName"
            :label="$t(`popFillInInfomation.administratorInfo.name`)"
          ></text-input>
        </el-col>

        <!--管理员邮箱  -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="adminEmail"
            :rules="rules.adminEmail"
            v-model="form.adminEmail"
            :label="$t(`popFillInInfomation.administratorInfo.email`)"
          ></text-input>
        </el-col>

        <!--管理员手机号  -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="adminMobile"
            :rules="rules.adminMobile"
            v-model="form.adminMobile"
            :label="$t(`popFillInInfomation.administratorInfo.phone`)"
          ></text-input>
        </el-col>
        <!--贸易模式  -->
        <el-col v-bind="row2">
          <el-form-item
            :rules="rules.tradeType"
            prop="tradeType"
            :label="$t(`popFillInInfomation.storeInfo.tradeType`)"
          >
            <el-radio-group v-model.number="form.tradeType">
              <el-radio :label="1">
                {{ $t(`popFillInInfomation.storeInfo.tradeTypeRadio1`) }}
              </el-radio>

              <el-radio :label="2">
                {{ $t(`popFillInInfomation.storeInfo.tradeTypeRadio2`) }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!--入仓模式  -->
        <el-col v-bind="row2">
          <el-form-item
            :rules="rules.warehousingMode"
            prop="warehousingMode"
            :label="$t(`popFillInInfomation.storeInfo.warehousingMode`)"
          >
            <el-radio-group v-model.number="form.warehousingMode">
              <el-radio :label="1">
                {{ $t(`popFillInInfomation.storeInfo.warehousingModeRadio1`) }}
              </el-radio>

              <el-radio :label="2">
                {{ $t(`popFillInInfomation.storeInfo.warehousingModeRadio2`) }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!-- 是否涉及仓库对接 -->
        <el-col v-bind="row1">
          <text-input
            :otherBind="otherBind"
            prop="warehousingMemo"
            v-model="form.warehousingMemo"
            :label="$t(`popFillInInfomation.storeInfo.warehousingMemo`)"
            :rules="rules.warehousingMemo"
            :p="$t(`popFillInInfomation.storeInfo.warehousingMemoHoldPlace`)"
          >
          </text-input>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
//公共组件导入处;
import textInput from "@/views/formComponents/textInput";
import categoryInfo from "./categoryInfo";
import uplodeInput3 from "@/views/formComponents/uplodeInput3";
//子组件导入处;

//方法导入处;

export default {
  components: {
    textInput,
    categoryInfo,
    uplodeInput3,
  },
  data() {
    return {
      otherBind: {
        size: "medium",
      },
    };
  },
  props: {
    row2: [Object],
    row1: [Object],
    gutter: [Number],
    formData: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    form: {
      get: function() {
        return this.formData;
      },
      set: function(value) {
        this.$emit("update:formData", value);
      },
    },
    getSubInfo() {
      let arr = this.$t(`popFillInInfomation.popShopInfo`);
      let info = arr.filter((item) => item.value == this.form.storeType);
      this.initStoreName(info[0]);
      return info[0];
    },
    getOtherTipList() {
      return this.$t(`popFillInInfomation.popShopInfoObj.shopLogoTipList`);
    },
  },
  methods: {
    // 根据品牌名称自动生成店铺名
    initStoreName(getSubInfo) {
      console.log(getSubInfo);
      // let value = this.form.brandName;
      // this.form.storeName = value ? `${value}${getSubInfo.shopType}` : null;
    },

    uplodeChange(value) {
      console.log("处理店铺照片:", value);
      this.form = { ...this.form, ...value };
      this.$parent.$parent.$parent.$refs["applyAgentForm"].validateField(
        Object.keys(value)[0]
      );
    },
  },
};
</script>
<style scoped lang="less">
.miaoshu_box {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 2px;
  max-width: 540px;
  padding: 24px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
}
.head_box {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  color: black;
  font-size: 14px;
  img {
    width: 50px;
    height: 50px;
  }
}
.wap {
  .pd_box {
    // padding-left: 12px;
  }
}
</style>
