var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main_containter",class:_vm.system.isPhone ? 'wap' : 'pc'},[_c('div',{staticClass:"pd_box"},[_c('el-row',{attrs:{"gutter":_vm.gutter}},[_c('el-col',_vm._b({},'el-col',{
          xs: 24,
          sm: 24,
          md: 24,
          lg: 18,
          xl: 18,
        },false),[_c('el-form-item',{attrs:{"prop":"categoryJson","label":_vm.$t("popFillInInfomation.brand.categoryInfo.category")}},[_c('el-cascader',_vm._b({ref:"category",staticStyle:{"width":"100%"},attrs:{"options":_vm.categoryList,"props":_vm.cascaderProps,"placeholder":"请选择经营类目","clearable":""},on:{"change":_vm.handleChange,"focus":_vm.categoryListFocus},model:{value:(_vm.form.categoryJson),callback:function ($$v) {_vm.$set(_vm.form, "categoryJson", $$v)},expression:"form.categoryJson"}},'el-cascader',_vm.otherBind,false))],1)],1),_c('el-col',_vm._b({},'el-col',{
          xs: 24,
          sm: 24,
          md: 24,
          lg: 6,
          xl: 6,
        },false),[_c('a',{staticClass:"a_font",attrs:{"href":("https://assets-img.hooloogostore.com/xlsxs/基础保证金标准.docx?timestamp=" + (new Date().getTime()))}},[_vm._v("类目及保证金查询")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }