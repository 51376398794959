<template>
  <div class="all">
    <top-menu-bar
      v-if="!system.isPhone"
      :curScroll="curScroll"
      @curIndex="curIndex"
    />
    <bar-wap
      v-if="system.isPhone"
      class="wap_bar_menu"
      :curScroll="curScroll"
      @curIndex="curIndex"
    />

    <div :class="system.isPhone ? 'wap_form' : 'form'">
      <apply-form></apply-form>
    </div>
    <!-- <bottom-det v-if="!system.isPhone" /> -->

    <!-- <wap-bottom v-if="system.isPhone" /> -->
  </div>
</template>
<script>
import applyForm from "@/views/applyContact/applyPop/components/applyForm";
import TopMenuBar from "@/views/publicComponents/TopMenuBar";
// import BottomDet from "@/views/publicComponents/BottomDet";
// import WapBottom from "@/views/publicComponents/WapBottom";
import BarWap from "@/views/publicComponents/BarWap";

export default {
  components: {
    TopMenuBar,
    applyForm,
    // BottomDet,
    // WapBottom,
    BarWap
  },
  data() {
    return {
      curScroll: 0
    };
  },
  methods: {
    curIndex(index) {
      this.curScroll = index;
    }
  }
};
</script>
<style lang="less" scoped>
.footer_con {
  padding-bottom: 100px;
}
.all {
  // min-width: 750px;
  text-align: left;
  background-color: #fafafa;
}
.form {
  position: relative;
  // width: calc(100vw * 127.2 / 192);
  // // min-width: 750px;
  // background-color: white;
  // height: auto;
  // margin: auto;
  // margin-top: -50px;
  // padding-top: 44px;
  // box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  // padding-bottom: 36px;
  // margin-bottom: 50px;
}
.dailitupian {
  width: 100%;
}
// 移动端样式
.wap_form {
  text-align: left;
  background-color: #fafafa;
}
.wap_header {
  position: relative;
  margin-top: calc(100vw * 13 / 37.5);
  height: calc(100vw * 5 / 37.5);
  margin-top: calc(100vw * 8 / 37.5);
  font-family: "FF1";
  color: rgba(25, 119, 251, 1);
  color: rgba(191, 146, 100, 1);
  margin-right: 20px;
  margin-left: 20px;
  text-align: left;
}
</style>
