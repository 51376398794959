<template>
  <div class="main_containter" :class="system.isPhone ? 'wap' : 'pc'">
    <el-row :gutter="gutter">
      <!--品牌类型 -->
      <el-col v-bind="row1">
        <el-form-item
          :prop="'popStoreBrandList.' + itemIndex + '.brandType'"
          :rules="rules.brandType"
          :label="$t(`popFillInInfomation.brand.brandType`)"
        >
          <el-radio-group v-model.number="formItem.brandType">
            <el-radio :label="1">
              {{ $t(`popFillInInfomation.brand.brandTypeRadio1`) }}
            </el-radio>
            <el-radio :label="2">
              {{ $t(`popFillInInfomation.brand.brandTypeRadio2`) }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <!-- 品牌名称 -->
      <el-col v-bind="row2">
        <!-- <text-input
          :otherBind="otherBind"
          :rules="rules.brandName"
          :prop="'popStoreBrandList.' + itemIndex + '.brandName'"
          v-model="formItem.brandName"
          :label="$t(`popFillInInfomation.brand.brandInfo.brandName`)"
          @onChange="initStoreName"
        ></text-input> -->
        <el-form-item
          :prop="'popStoreBrandList.' + itemIndex + '.brandName'"
          :rules="rules.brandName"
          class="zai_css"
          :label="$t(`popFillInInfomation.brand.brandInfo.brandName`)"
        >
          <!-- <el-select
            v-model="formItem.brandName"
            filterable
            remote
            style="width: 100%;"
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="(name) => remoteMethod({ name })"
            :loading="loading"
            @change="(id) => handleBrandChange(itemIndex, 1, id)"
            :disabled="otherEditStatus.brandNameEditStatus"
          >
            <el-option
              v-for="item in brandNameList"
              :key="item.name + item.id"
              :label="getOptionString(item)"
              :value="item.id"
            >
            </el-option>
          </el-select> -->

          <el-autocomplete
            style="width: 100%;"
            v-model="formItem.brandName"
            :fetch-suggestions="
              (name, callback) => remoteMethod({ name }, callback)
            "
            placeholder="请输入内容"
            @select="(item) => handleBrandChange(itemIndex, 1, item)"
          >
            <template slot-scope="{ item }">
              <span>{{ getOptionString(item) }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
      <!--商标注册号  -->
      <el-col v-bind="row2">
        <!-- <text-input
          :otherBind="otherBind"
          :prop="'popStoreBrandList.' + itemIndex + '.trademarkSn'"
          :rules="rules.trademarkSn"
          v-model="formItem.trademarkSn"
          :label="$t(`popFillInInfomation.brand.brandInfo.trademarkSn`)"
        ></text-input> -->
        <el-form-item
          :prop="'popStoreBrandList.' + itemIndex + '.trademarkSn'"
          :rules="rules.trademarkSn"
          class="zai_css"
          :label="$t(`popFillInInfomation.brand.brandInfo.trademarkSn`)"
        >
          <!-- <el-select
            v-model="formItem.trademarkSn"
            filterable
            remote
            style="width: 100%;"
            placeholder="请输入关键词"
            :remote-method="(trademarkSn) => remoteMethod({ trademarkSn })"
            :loading="loading"
            @change="(id) => handleBrandChange(itemIndex, 2, id)"
            :disabled="otherEditStatus.trademarkSnEditStatus"
          >
            <el-option
              v-for="item in brandTrademarkSNList"
              :key="item.name + item.trademarkSn"
              :label="getOptionString(item)"
              :value="item.id"
            >
            </el-option>
          </el-select> -->

          <el-autocomplete
            style="width: 100%;"
            v-model="formItem.trademarkSn"
            :fetch-suggestions="
              (trademarkSn, callback) => remoteMethod({ trademarkSn }, callback)
            "
            placeholder="请输入内容"
            @select="(item) => handleBrandChange(itemIndex, 2, item)"
            :disabled="otherEditStatus.trademarkSnEditStatus"
          >
            <template slot-scope="{ item }">
              <span>{{ getOptionString(item) }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
      <!--商标注册人  -->
      <el-col v-bind="row2">
        <text-input
          :otherBind="otherBind"
          :prop="'popStoreBrandList.' + itemIndex + '.trademarkSnOwner'"
          :rules="rules.trademarkSnOwner"
          v-model="formItem.trademarkSnOwner"
          :label="$t(`popFillInInfomation.brand.brandInfo.trademarkSnOwner`)"
          :disabled="otherEditStatus.trademarkSnOwner"
        ></text-input>
      </el-col>
      <!--商标有效期  -->
      <el-col v-bind="row2">
        <el-form-item
          :prop="'popStoreBrandList.' + itemIndex + '.certificationTime'"
          :rules="rules.certificationTime"
          :label="
            $t(`popFillInInfomation.brand.registration.certificationTime`)
          "
          :disabled="otherEditStatus.certificationTime"
        >
          <el-date-picker
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            v-model="formItem.certificationTime"
            type="date"
            v-bind="otherBind"
            placeholder="请选择"
            :editable="!system.isPhone"
            :disabled="otherEditStatus.certificationTime"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <!-- 商标注册证 -->
      <el-col v-bind="row2">
        <uplode-input3
          :prop="'popStoreBrandList.' + itemIndex + '.certificationPicUrl'"
          :rules="[
            {
              ...rules.certificationPicUrl[0],
              itemIndex: itemIndex,
            },
          ]"
          :label="$t(`popFillInInfomation.brand.certificationPicUrl`)"
          keyName="certificationPicUrl"
          @uplodeChange="uplodeChange"
          :value="formItem.certificationPicUrl"
          :disabled="otherEditStatus.certificationPicUrl"
        >
          <template #tip>
            {{
              $t(
                `popFillInInfomation.brand.registration.certificationPicUrlTip`
              )
            }}
          </template>
        </uplode-input3>
      </el-col>
      <!-- 品牌LOGO   :isSize="{ width: 500, height: 500, scale: true }" -->
      <el-col v-bind="row2">
        <uplode-input3
          :prop="'popStoreBrandList.' + itemIndex + '.brandLogo'"
          :label="$t(`popFillInInfomation.brand.brandInfo.brandLogo`)"
          keyName="brandLogo"
          :rules="[
            {
              ...rules.brandLogo[0],
              itemIndex: itemIndex,
            },
          ]"
          @uplodeChange="uplodeChange"
          :value="formItem.brandLogo"
          :limit="2"
          :disabled="otherEditStatus.brandLogo"
        >
          <template #tip>
            <div>
              <ul>
                <li v-for="item in getOtherTipList" :key="item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </template>
        </uplode-input3>
      </el-col>
      <!-- 授权等级 -->
      <el-col v-if="formItem.brandType == 2" v-bind="row2">
        <text-input
          :otherBind="otherBind"
          :rules="[
            {
              ...rules.authorizationLevel[0],
              itemIndex: itemIndex,
            },
          ]"
          :prop="'popStoreBrandList.' + itemIndex + '.authorizationLevel'"
          v-model="formItem.authorizationLevel"
          :label="$t(`popFillInInfomation.brand.authorizationLevel`)"
          @onChange="initStoreName"
        ></text-input>
      </el-col>
      <el-col v-if="formItem.brandType == 2" v-bind="row2">
        <div class="width100">
          授权等级，指开店主体所持有的品牌商标授权等级，分为一级独占授权、一级授权，二级授权、三级授权和仅采销链路等
        </div>
      </el-col>
      <!--授权有效期：  -->
      <el-col v-if="formItem.brandType == 2" v-bind="row2">
        <el-form-item
          :prop="
            `popStoreBrandList.${itemIndex}${
              formItem.authorizationLongPeriod
                ? '.authorizationLongPeriod'
                : '.authorizationPeriod'
            }`
          "
          :rules="[
            {
              ...(formItem.authorizationLongPeriod
                ? rules.authorizationLongPeriod[0]
                : rules.authorizationPeriod[0]),
              itemIndex: itemIndex,
            },
          ]"
          :label="$t(`popFillInInfomation.brand.authorizationPeriod`)"
        >
          <div class="yihang">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="formItem.authorizationPeriod"
              type="date"
              v-bind="otherBind"
              placeholder="请选择"
              :editable="!system.isPhone"
              :disabled="formItem.authorizationLongPeriod == 1"
            >
            </el-date-picker>
            <el-checkbox
              @change="changqiEvent($event, formItem)"
              :true-label="1"
              :false-label="0"
              v-model="formItem.authorizationLongPeriod"
              t="长期"
            >
              {{ $t(`popFillInInfomation.longTime`) }}
            </el-checkbox>
          </div>
        </el-form-item>
      </el-col>
      <el-col v-bind="row2">
        <div class="width100"></div>
      </el-col>
      <!-- 品牌资质文件 -->
      <el-col v-if="formItem.brandType == 2" v-bind="row2">
        <!-- 多文件上传下载 -->
        <upload-file-more
          :prop="'popStoreBrandList.' + itemIndex + '.brandQualificationUrl'"
          :rules="[{ ...rules.brandQualificationUrl[0], itemIndex: itemIndex }]"
          :tips="tips"
          :dataList.sync="formItem.brandQualificationUrl"
          :label="$t(`popFillInInfomation.brand.brandQualificationUrl`)"
        >
        </upload-file-more>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import uplodeInput3 from "@/views/formComponents/uplodeInput3";
import textInput from "@/views/formComponents/textInput";
import uploadFileMore from "@/views/formComponents/uploadfilemore";

export default {
  components: { uplodeInput3, textInput, uploadFileMore },
  data() {
    return {
      otherBind: {
        size: "medium",
      },
      tips: [
        "请上传《品牌授权书》或采销进货凭证",
        "1、以商标持有人为源头出发的，授权至开店公司的三级以内完整授权链条。三级授权链路是指：商标持有人→授权方A→授权方B→入驻主体公司",
        "2、商标持有人是开店公司/法人，无需提交授权书",
        "3、采销链路要求3级以内，采销进货凭证需在3个月内",
        "4、所有授权都要加盖授权方及开店公司红章",
      ],
      searching: false,
      brandNameList: [],
      brandTrademarkSNList: [],
      loading: false,

      otherEditStatus: {
        trademarkSnEditStatus: false,
        brandNameEditStatus: false,
        trademarkSnOwner: false,
        certificationPicUrl: false,
        brandLogo: false,
        certificationTime: false,
      },
    };
  },
  emit: ["validate"],
  props: {
    row2: [Object],
    row1: [Object],
    gutter: [Number],
    formData: {
      type: Object,
      default: () => {},
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    rules: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    form: {
      get: function() {
        return this.formData;
      },
      set: function(value) {
        this.$emit("update:formData", value);
      },
    },
    formItem: {
      get: function() {
        return this.formData.popStoreBrandList[this.itemIndex];
      },
    },
    getOtherTipList() {
      return this.$t(`popFillInInfomation.brand.brandInfo.brandLogoTipList`);
    },
    getSubInfo() {
      let arr = this.$t(`popFillInInfomation.popShopInfo`);
      let info = arr.filter((item) => item.value == this.form.storeType);
      return info[0];
    },
  },
  methods: {
    getOptionString(item) {
      return (
        this.$t(`popFillInInfomation.brand.brandInfo.brandName`) +
        ":" +
        (item.name ?? "--") +
        "\n" +
        this.$t(`popFillInInfomation.brand.brandInfo.trademarkSn`) +
        ":" +
        (item.trademarkSn ?? "--")
      );
    },
    handleBrandChange(index, type, item) {
      const obj = { ...item };
      obj.brandName = obj.name;
      obj.brandId = obj.id;

      this.resetEditStatus(1, type, obj, index);

      this.$emit("validate", index);
    },
    remoteMethod({ name, trademarkSn }, callback) {
      //重置数据
      this.resetEditStatus(0, 0, null, 0);
      if (name || trademarkSn) {
        this.brandNameList = [];
        this.brandTrademarkSNList = [];
        const data = {
          name,
          trademarkSn,
        };
        if (this.popStoreId) {
          data.popStoreId = this.popStoreId;
        }
        this.searching = true;

        this.$request("/api/product/brand/findBrandList", {
          method: "POST",
          data,
        })
          .then((res) => {
            this.searching = false;
            if (res.code === 1) {
              callback(res.data);
            }
          })
          .catch(() => {
            this.searching = false;
          });
      } else {
        callback([]);
      }
    },

    // 改变编辑状态
    resetEditStatus(set, type, obj, index) {
      if (set === 1) {
        // 控制编辑状态
        if (type === 1) {
          const trademarkSnEditStatus =
            obj.trademarkSn != null && obj.trademarkSn.length > 0;
          this.$set(
            this.otherEditStatus,
            "trademarkSnEditStatus",
            trademarkSnEditStatus
          );
        }
        if (type === 2) {
          const brandNameEditStatus =
            obj.brandName != null && obj.brandName.length > 0;
          this.$set(
            this.otherEditStatus,
            "brandNameEditStatus",
            brandNameEditStatus
          );
        }

        Object.keys(this.otherEditStatus).forEach((key) => {
          if ("brandNameEditStatus" == key || "trademarkSnEditStatus" == key) {
            return;
          }
          this.$set(
            this.otherEditStatus,
            key,
            obj[key] != null && obj[key].length > 0
          );
        });
        //覆盖当前编辑数据
        const item = { ...this.formData.popStoreBrandList[index], ...obj };
        this.formData.popStoreBrandList.splice(index, 1, item);
      } else {
        this.$set(this.otherEditStatus, "trademarkSnEditStatus", false);
        this.$set(this.otherEditStatus, "brandNameEditStatus", false);
        Object.keys(this.otherEditStatus).forEach((key) => {
          this.$set(this.otherEditStatus, key, false);
        });

        //重置brandId
        const item = {
          ...this.formData.popStoreBrandList[index],
          ...{ brandId: null },
        };

        this.formData.popStoreBrandList.splice(index, 1, item);
      }
    },
    // 根据品牌名称自动生成店铺名
    initStoreName(value) {
      console.log(value);
      // this.formItem.storeName = value
      //   ? `${value}${this.getSubInfo.shopType}`
      //   : null;
    },
    uplodeChange(value) {
      console.log("处理执照照片:", value);
      let key = Object.keys(value)[0];
      this.form.popStoreBrandList[this.itemIndex][key] = value[key];
      this.$parent.$parent.$parent.$refs["applyAgentForm"].validateField(
        `popStoreBrandList.${this.itemIndex}.${key}`
      );
    },

    handleChange(value) {
      console.log(this.$refs.asdfasdf.getCheckedNodes());
      console.log(value, arguments);
    },
    changqiEvent(e, item) {
      console.log(e, item);
      if (e == 1) {
        item.authorizationPeriod = null;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.form.xx = 1;
    }, 3000);
  },
  beforeDestroy() {},
  activated() {},
};
</script>
<style scoped lang="less">
::v-deep .el-cascader--medium,
.el-date-editor {
  width: 100%;
}
.yihang {
  display: flex;
  align-items: center;
  gap: 14px;
}
.zai_css {
  padding-top: 8px;
  ::v-deep .el-form-item__label {
    line-height: 20px;
  }
  ::v-deep .el-form-item__content {
    line-height: 20px;
  }
  .item_box_tip {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 6px;
  }
}
::v-deep .tip {
  display: flex;
  align-items: center;
}
.pc {
  ::v-deep .box_title {
    font-size: calc(100vw * 1.4 / 192);
    font-weight: 600;
  }
}
.wap {
  ::v-deep .box_title {
    font-size: calc(100vw * 1.5 / 37.5);
    font-weight: 600;
  }
}
.width100 {
  width: 100%;
  height: 62px;
  line-height: 40px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
