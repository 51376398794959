<template>
  <div :class="system.isPhone ? 'wap_form_bar ' : 'form_bar'">
    <el-steps
      :space="system.isPhone ? '90px ' : '100%'"
      :direction="system.isPhone ? 'vertical' : 'horizontal'"
      :active="5"
    >
      <el-step
        v-for="item in stepsInfo"
        :key="item.title"
        :title="item.title"
        :description="item.description"
      >
      </el-step>
      <el-step class="none" title=""></el-step>
    </el-steps>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  components: {},
  data() {
    return {
      stepsInfo: this.$t("popFillInInfomation.steps")
      // whoApply: this.$route.query.whoApply || "personalApply"
    };
  },
  props: ["active"],
  computed: {},
  methods: {},
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.none {
  display: none;
}

.form_bar {
  // 步骤条一些PC端展示样式重置
  // 标题描述等父容器的样式重置
  ::v-deep .el-step__main.el-step__main {
    white-space: normal;
    padding-left: 38px;
  }

  // 等待中的步骤条图标块的样式设置
  ::v-deep .is-wait > .el-step__icon.el-step__icon {
    background: #bfbfbf;
    color: white;
    border: none;
  }

  // 步骤之间的线条样式设置
  ::v-deep .el-step__line.el-step__line {
    height: 1px;
    left: 38px;
    right: 14px;
  }
  // 进行中/结束时的线条样式这种
  ::v-deep .el-step__line-inner.el-step__line-inner {
    border: none;
    height: 1px;
    background-color: black;
  }
  // 描述块样式设置
  ::v-deep .el-step__description {
    color: #bfbfbf;
  }
  // 标题块样式设置
  ::v-deep .el-step__title {
    color: #bfbfbf;
    font-weight: 600;
    font-size: calc(100vw * 1.8 / 192);
  }
  // 进行中的样式设置
  ::v-deep .is-process.is-process {
    color: black;
    .el-step__line {
      border: none;
      height: 1px;
      background-color: black;
    }
    // 进行中的步骤条图标块的样式设置
    .el-step__icon.el-step__icon {
      background-color: black;
      color: white;
      border: none;
    }
  }

  // 结束的样式设置
  ::v-deep .is-finish.is-finish {
    color: black;
    .el-step__line {
      border: none;
      height: 1px;
      background-color: black;
    }
    // 结束后的步骤条图标块的样式设置
    .el-step__icon.el-step__icon {
      background-color: black;
      color: white;
      border: none;
    }
  }
  // 步骤条一些PC端展示样式重置结束
}
.wap_form_bar {
  // 标题描述等父容器的样式重置
  ::v-deep .el-step__main.el-step__main {
    white-space: normal;
    padding-left: 18px;
    padding-top: 25px;
  }

  // 等待中的步骤条图标块的样式设置
  ::v-deep .is-wait > .el-step__icon.el-step__icon {
    background: #bfbfbf;
    color: white;
    border: none;
  }

  // 步骤之间的线条样式设置
  ::v-deep .el-step__line.el-step__line {
    width: 1px;
  }
  // 进行中/结束时的线条样式这种
  ::v-deep .el-step__line-inner.el-step__line-inner {
    border: none;
    width: 1px;
    background-color: black;
  }
  // 描述块样式设置
  ::v-deep .el-step__description {
    color: #bfbfbf;
    font-size: calc(100vw * 1.4 / 37.5);
    line-height: calc(100vw * 1.8 / 37.5);
  }
  // 标题块样式设置
  ::v-deep .el-step__title {
    color: #bfbfbf;
    font-weight: 600;
    font-size: calc(100vw * 1.6 / 37.5);
    line-height: calc(100vw * 1.8 / 37.5);
  }

  // 进行中的样式设置
  ::v-deep .is-process.is-process {
    color: black;
    .el-step__line {
      border: none;
      width: 1px;
      background-color: black;
    }
    // 进行中的步骤条图标块的样式设置
    .el-step__icon.el-step__icon {
      background-color: black;
      color: white;
      border: none;
    }
  }

  // 结束的样式设置
  ::v-deep .is-finish.is-finish {
    color: black;
    .el-step__line {
      border: none;
      width: 1px;
      background-color: black;
    }
    // 结束后的步骤条图标块的样式设置
    .el-step__icon.el-step__icon {
      background-color: black;
      color: white;
      border: none;
    }
  }
  // 步骤条一些PC端展示样式重置结束
}
</style>
